import React, { Component } from 'react';
import 'dealer/styles/App.css';
import { NavLink } from 'react-router-dom'
import Flexbox from 'flexbox-react';

import * as Constants from 'Constants';

class SidebarItem extends Component {

  renderIcon = () => {
    if (this.props.iconName) {
      const icon2x = require('dealer/assets/'+this.props.iconName+'@2x.png');
      const icon3x = require('dealer/assets/'+this.props.iconName+'@3x.png');

      return (
        <img
          alt=''
          src={require("dealer/assets/"+this.props.iconName+".png")}
          srcSet={`
            ${icon2x} 2x,
            ${icon3x} 3x
          `}
        />
      );
    } else if (this.props.svgIconName) {
      return (
        <img
          alt=""
          src={require(`dealer/assets/${this.props.svgIconName}.svg`)}
          width={24}
        />
      );
    }
    return <div style={{width: 26}}></div>;
  }

  render() {

    let leftPadding = 28;
    if (this.props.submenuLevel) {
      leftPadding += 28 * (this.props.submenuLevel);
    }
    if (this.props.link === "/") {
      return (
        <NavLink
          exact
          className="sidebarNav"
          onClick={this.props.onClick}
          to={this.props.link}
          style={{ textDecoration: 'none', backgroundColor: Constants.TertiaryColor, width: "100%" }}
          activeStyle={{ backgroundColor: Constants.DarkestTertiaryColor }}>
          <Flexbox data-tut={this.props.dataTut} flexDirection="row"  alignItems="center" style={{color: 'white', textDecoration: 'none', paddingLeft: leftPadding, paddingTop: 10, paddingBottom: 10, paddingRight: 20}} >
            { this.renderIcon() }
            <div style={{marginLeft: 10}}>
              {this.props.title}
            </div>
          </Flexbox>
        </NavLink>
      );
    }

    return (
      <NavLink
        className="sidebarNav"
        onClick={this.props.onClick}
        to={this.props.link}
        style={{ textDecoration: 'none', backgroundColor: Constants.TertiaryColor, width: "100%" }}
        activeStyle={{ backgroundColor: Constants.DarkestTertiaryColor }}>
        <Flexbox data-tut={this.props.dataTut} flexDirection="row"  alignItems="center" style={{color: 'white', textDecoration: 'none', paddingLeft: leftPadding, paddingTop: 10, paddingBottom: 10, paddingRight: 20}} >
          { this.renderIcon() }
          <div style={{marginLeft: 10}}>
            {this.props.title}
          </div>
        </Flexbox>
      </NavLink>
    );
  }
}

export default SidebarItem;
