import {executeBasicNetworkCall} from "../../lib/Utils";
import {HOST} from "../../Constants";

export function createNewVideoArticle(data, callback) {
    return (dispatch, getState) => {
        let params = {
            title: data.title,
            release_date: data.release_date,
            abstract: data.abstract,
            is_draft: data.is_draft,
            media: data.media,
            original_id: data.original_id
        };

        
        params = JSON.stringify(params);

        executeBasicNetworkCall(
            "create_burst",
            params,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);

                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.message);
                    }
                }
            },
            error => {
                console.log(error);
                callback(false, error);
            },
            false,
            status => {
                callback(false, `Server ${status} Error`);
            },
            `${HOST()}/API/v2/CreateBurst`
        );
    };
}
