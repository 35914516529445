import React, { Component } from 'react';
import Flexbox from 'flexbox-react';
// import pig from '../assets/pig.png';
import '../styles/error.css';

class ErrorView extends Component {

  render() {
    return (
      <Flexbox flexDirection="column" justifyContent="center" alignItems="center">
        {/*<div className="pig-biplane">*/}
        {/*  <img src={pig} alt="Flying Pig" className="pig"/>*/}
        {/*</div>*/}
        <h1 style={{color: 'black'}}>
          Oops!
        </h1>
        <br/>
        <br/>
        <div style={{color: 'black'}}>
          An error has occurred.  Our development team has been notified.
        </div>
        <br/>
        <br/>
        <div style={{color: 'black'}}>
          Return to <a href="/" alt="Home" >Home Page</a>.
        </div>
      </Flexbox>
    );
  }
}

export default ErrorView;
