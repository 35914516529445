import createReducer from '../lib/createReducer'
import * as types from '../actions/types'


export const register = createReducer({
    first_name: null,
    last_name: null,
    email: null,
    region: null,
    password: null,
    promo_code: null,
    phone: null,
    marketing_type: null
}, {

    [types.SET_NEW_ACCOUNT_INFO](state, action) {
        let newState = Object.assign({}, state);

        if (action.account_info == null) {
            newState.first_name = null;
            newState.last_name = null;
            newState.email = null;
            newState.region = null;
            newState.password = null;
            newState.promo_code = null;
            newState.phone = null;
            newState.marketing_type = null;
        } else {
            newState.first_name = action.account_info.first_name;
            newState.last_name = action.account_info.last_name;
            newState.email = action.account_info.email;
            newState.region = action.account_info.region;
            newState.password = action.account_info.password;
            newState.promo_code = action.account_info.promo_code;
            newState.phone = action.account_info.phone;
            newState.marketing_type = action.account_info.marketing_type;
        }

        return newState;
    },

});

export const update = createReducer({
    uid: null,
    first_name: null,
    last_name: null,
    email: null,
}, {

    [types.UPDATE_ACCOUNT_INFO](state, action) {
        let newState = Object.assign({}, state);

        if (action.update_account_info == null) {
            newState.uid = null;
            newState.first_name = null;
            newState.last_name = null;
            newState.email = null;
        } else {
            newState.uid = action.update_account_info.uid;
            newState.first_name = action.update_account_info.first_name;
            newState.last_name = action.update_account_info.last_name;
            newState.email = action.update_account_info.email;
        }

        return newState;
    },

});
