import * as types from "./types";
import bugsnagClient from "../../lib/bugsnag";
import { executeBasicNetworkCall } from "../../lib/Utils";

export function checkLoginStatus() {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "user_logged_in",
            undefined,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x.error) {
                        console.log(x.message);
                    } else {
                        if (x.data) {
                            dispatch(setLoginStatus(true));
                            console.log("Has SessionID cookie...");
                        } else {
                            dispatch(setLoginStatus(false));
                            console.log("No SessionID cookie...");
                        }
                    }
                }
            },
            (error) => {
                console.log(error);
            },
        );
    };
}

export function login(username, password, failureCallback = () => {}, updateSubscription = () => {}) {
    return (dispatch, getState) => {
        const encodedUsername = encodeURIComponent(username);
        const encodedPassword = encodeURIComponent(password);

        const params = `username=${encodedUsername}&password=${encodedPassword}`;

        executeBasicNetworkCall(
            "login",
            params,
            "application/x-www-form-urlencoded",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);

                    if (x.error) {
                        if (x.code === 107) {
                            dispatch(setUpdateAccountInfo(x.data));
                            updateSubscription();
                        } else {
                            failureCallback(x.message);
                        }
                    } else {
                        dispatch(setLogin(x));
                        let info = {
                            co_branded_image: x.co_branded_image,
                            co_branded_website_url: x.co_branded_website_url,
                        };
                        dispatch(setCoBrandedInfo(info));
                        dispatch(setMainTourAsWatched(x.has_viewed_tutorial));
                        bugsnagClient.user = {
                            id: x.uid,
                            name: x.name,
                            email: username,
                        };
                    }
                }
            },
            (error) => {
                console.log(error);
            },
        );
    };
}

export function logout() {
    return (dispatch, getState) => {
        bugsnagClient.user = {};

        executeBasicNetworkCall(
            "logout",
            undefined,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);

                    if (x.error) {
                        console.log(x.message);
                    } else {
                        console.log(x.message);
                    }
                }
            },
            (error) => {
                console.log(error);
            },
        );
        //TODO clear all redux states here!!!
        console.log("logging out");
        dispatch(executeLogout());
        console.log("resetting to defaults");
        dispatch(resetToDefaults());
        dispatch(setAMCookie());
        dispatch(setPMCookie());
    };
}

export function resetPassword(email, success = () => {}, failure = () => {}) {
    return (dispatch, getState) => {
        let params = {
            email: email,
        };

        params = JSON.stringify(params);

        executeBasicNetworkCall(
            "reset_password_request",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);

                    if (x.error) {
                        failure(x.message);
                    } else {
                        success(x.message);
                    }
                }
            },
            (error) => {
                console.log(error);
            },
        );

        //TODO clear all redux states here!!!
        console.log("logging out");
        dispatch(executeLogout());
    };
}

export function shownCoBrand() {
    return (dispatch, getState) => {
        dispatch(coBrandShown());
    };
}

function coBrandShown() {
    return {
        type: types.SHOWN_CO_BRAND,
    };
}

function executeLogout() {
    return {
        type: types.LOGOUT,
    };
}

function resetToDefaults() {
    return {
        type: types.RESET_TO_DEFAULTS,
    };
}

function setLoginStatus(isLoggedIn) {
    return {
        type: types.SET_IS_LOGGED_IN_STATUS,
        isLoggedIn,
    };
}

function setLogin(info) {
    return {
        type: types.SET_LOGIN,
        info,
    };
}

function setCoBrandedInfo(info) {
    return {
        type: types.SET_CO_BRANDED_INFO,
        info,
    };
}

function setMainTourAsWatched(has_viewed_tutorial = true) {
    return {
        type: types.SET_MAIN_TOUR_AS_WATCHED___WEB,
        has_viewed_tutorial,
    };
}

function setUpdateAccountInfo(update_account_info) {
    return {
        type: types.UPDATE_ACCOUNT_INFO,
        update_account_info,
    };
}

// function setAccountInfo(account_info) {
//     return {
//         type: types.SET_NEW_ACCOUNT_INFO,
//         account_info
//     }
// }

export function changeMyDetails(data) {
    return {
        type: types.CHANGE_MY_DETAILS,
        data,
    };
}

export function setBackdoorLogin(x, am_auth_token, pm_auth_token, callback = () => {}) {
    return (dispatch) => {
        dispatch(executeLogout());
        dispatch(resetToDefaults());
        dispatch(setLogin(x));
        dispatch(setAMCookie(am_auth_token));
        dispatch(setPMCookie(pm_auth_token));
        callback();
    };
}

function setAMCookie(am_auth_token = null) {
    return {
        type: types.SET_AM_COOKIE,
        am_auth_token,
    };
}

function setPMCookie(pm_auth_token = null) {
    return {
        type: types.SET_PM_COOKIE,
        pm_auth_token,
    };
}
