import React from 'react';

let helpButton = require('../assets/help_black.svg');

export const BasicTourSteps = [
  {
    target: '[data-tut="reactour__topbar"]',
    disableBeacon: true,
    title: "Welcome to AgMarket.Net",
    content: (
      <div>
        <div style={{textAlign: "left"}}>
          If you ever get lost, you can click on our logo to get back to the main screen.
        </div>
        <br/>
        <div style={{textAlign: "left"}}>
          There are help buttons in the app that you can click to get more information.
        </div>
        <br/>
        <img src={helpButton} alt="Help Button"/>
      </div>
    ),
  },
  {
    target: '[data-tut="reactour__sidebar-user"]',
    isFixed: true,
    content: 'Here is information about your account and an option to logout when you are finished using the app.',
  },
  {
    target: '[data-tut="reactour__home"]',
    isFixed: true,
    content: 'Home shows a summary of the app.',
  },
  {
    target: '[data-tut="reactour__cashbids"]',
    isFixed: true,
    content: 'The Cash Bids section will show you all grain elevator cash bids within the search radius that you specify',
  },
  {
    target: '[data-tut="reactour__myfarm"]',
    isFixed: true,
    content: 'My Farm gives an at a glance summary of how your farm is progressing.',
  },
  {
    target: '[data-tut="reactour__myfarm-reports"]',
    isFixed: true,
    content: 'Input details about your farm here to get personalized reports about how your farm is doing.',
  },
  {
    target: '[data-tut="reactour__futures"]',
    isFixed: true,
    content: 'Check the current corn, soybean, and wheat markets here.',
  },
  {
    target: '[data-tut="reactour__news"]',
    isFixed: true,
    content: 'Find videos, podcasts, and news stories here curated by Matt and his team.',
  },
  {
    target: '[data-tut="reactour__crm"]',
    isFixed: true,
    content: 'Manage contact information, password, and membership details here.',
  },
  {
    target: '[data-tut="reactour__sidebar-anchor"]',
    isFixed: true,
    content: 'Control the visibility of the sidebar menu with this button',
  }
]
