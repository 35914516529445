import createReducer from '../lib/createReducer'
import * as types from '../actions/types'


const DEFAULTS = {
    endpoints: null,
    articles: []
};

export const storydynamics = createReducer(DEFAULTS, {

    [types.RESET_TO_DEFAULTS](state, action) {
        let newState = Object.assign({}, state);
        newState.articles = [];
        return newState;
    },

    [types.SET_ENDPOINT_URLS](state, action) {
        let newState = Object.assign({}, state);
        if (action.info !== null) {
            newState.endpoints = action.info;
        } else {
            newState.endpoints = null;
        }
        return newState;
    },

    [types.SET_ARTICLES](state, action) {
        let newState = Object.assign({}, state);
        if (action.articles !== null && action.isAppending) {
            newState.articles = state.articles.concat(action.articles);
        }
        else if (action.articles !== null) {
            newState.articles = action.articles;
        } else {
            newState.articles = [];
        }
        return newState;
    },
});
