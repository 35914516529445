import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import rootReducer from '../reducers'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['barcharts', 'bennettapp', 'widgetstate', 'storydynamics', 'user']
  //blacklist: ['navigation']
}


const persistedReducer = persistReducer(persistConfig, rootReducer)

// middleware that logs actions
//const loggerMiddleware = createLogger({ predicate: (getState, action) => __DEV__  });

function configureStore(persistedReducer) {

  const DEV = (process.env.NODE_ENV !== "production")

  const middlewares = [thunkMiddleware, DEV && logger].filter(Boolean);


  const enhancer = compose(
    applyMiddleware(...middlewares),
  );
  return createStore(persistedReducer, undefined, enhancer);
}


export const store = configureStore(persistedReducer);
export const persistor = persistStore(store);
