import * as BarChartActions from './barchart';
import * as BennettAppActions from './bennettapp';
import * as WidgetStateActions from './widgetstate';
import * as StoryDynamicActions from './storydynamics';
import * as UserActions from './user';
import * as QueueActions from './queue';
import * as MediaActions from './media';
import * as ComposeActions from './compose';
import * as MessagesActions from './messages';
import * as PromoCodeActions from './promocodes';
import * as CRMExtensions from './crmextensions';

export const ActionCreators = Object.assign({},
    BarChartActions,
    BennettAppActions,
    WidgetStateActions,
    StoryDynamicActions,
    UserActions,
    QueueActions,
    MediaActions,
    ComposeActions,
    MessagesActions,
    PromoCodeActions,
    CRMExtensions
);
