import * as types from './types';

export function clearUpdateAndRegister(){
    return (dispatch, getState) => {
        dispatch(setUpdateAccountInfo(null))
        dispatch(setAccountInfo(null))
    }
}

export function setAccountInfo(account_info) {
    return {
        type: types.SET_NEW_ACCOUNT_INFO,
        account_info
    }
}

export function setUpdateAccountInfo(update_account_info) {
    return {
        type: types.UPDATE_ACCOUNT_INFO,
        update_account_info
    }
}