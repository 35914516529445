import React, {Component} from 'react';
import {Button, Modal} from 'semantic-ui-react';
import {CardElement, injectStripe} from 'react-stripe-elements'
import Flexbox from "flexbox-react";
import "../../styles/Stripe.css"
import {AuthGroup} from "../../../Constants";

class ExtendSubscriptionModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            promoCode: "",
            cardHolderName: props.user.fullName,
        }
    }

    componentDidMount() {
        this.props.getSubscriptionCost(this.forUID(), this.didGetSubscriptionCost);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedUID !== this.props.selectedUID ) {
            this.resetPrices();
            this.setState({
                promoCode: ""
            });

        }
    }

    resetPrices = () => {
        this.setState({
            lineItems: null,
            subTotal: null,
            total: null,
            base: null,
            promoCode: "",
            percentOff: null,
            showPromoCodeEntry: false
        });

        this.props.getSubscriptionCost(this.forUID(), this.didGetSubscriptionCost);
    };

    didGetSubscriptionCost = (successful, data) => {
        console.log("didGetSubscriptionCost", successful, data);
        if (successful) {
            // API call worked
            this.setState({
                lineItems: data.line_items,
                subtotal: data.subtotal,
                total: data.subtotal,
                base: data.base
            })
        } else {
            // API call failed
            this.resetPrices();
        }
    };

    openModal = () => {
        this.setState({open: true})
    };
    closeModal = () => {
        this.resetPrices();
        this.setState({open: false})
    };

    submitPurchase = async () => {

        let {token} = await this.props.stripe.createToken({
            name: this.state.cardHolderName
        });

        console.log("token", token);

        let promoCode;

        if (token && this.forUID()) {
            this.setState({
                isPaying: true
            });

            this.props.extendMembership(this.forUID(), token, promoCode, this.didExtendMembership);
        }
    };
    didExtendMembership = (successful, data) => {


        if (successful) {
            this.setState({
                isPaying: false
            });

            if(this.props.isForCRM) {
                this.props.refreshExtensionDetails();
            }
            this.props.changeMyDetails({subscription_expire_date: data})

            this.closeModal();
            this.props.setSuccess("Thank you!  Membership subscription extended.");

        } else {
            this.setState({
                isPaying: false,
                error: data
            });
        }
    };

    applyPromoCode = () => {
        this.setState({
            applyingPromoCode: true,
            promoCodeError: null
        });

        this.props.applyPromoCode(this.state.promoCode, this.forUID(), this.didApplyPromoCode);
    };
    didApplyPromoCode = (successful, data, subscription_expire_date=null) => {
        this.setState({
            applyingPromoCode: false
        });

        if (successful) {

            if (data === "vpp") {

                this.props.setSuccess("Volume Purchase Program Code Applied Successfully");
                if(this.props.isForCRM) {
                    this.props.refreshExtensionDetails();
                }
                this.props.changeMyDetails({subscription_expire_date: subscription_expire_date})
                this.closeModal();
                return;
            }

            this.setState({
                percentOff: data.percent_off,
                total: data.total,
                promoCodeError: null,
                showPromoCodeEntry: false
            })
        } else {
            this.setState({
                promoCodeError: data
            });
        }
    };

    forUID = () => {
        return this.props.user.uid !== this.props.selectedUID ? this.props.selectedUID : this.props.user.uid;
    };


    renderBaseCost = () => {

        if (this.state.base && !isNaN(this.state.base)) {
            const base = this.state.base;
            return (
                <div>
                    ${base.toFixed(2)}
                </div>
            );
        }

        return (
            <div className="loaderCRMExtension"/>
        );
    };
    renderTotalsBox = () => {
        return (
            <div style={{backgroundColor: "#e7ebef", padding: 10, borderRadius: 4}}>

                {this.state.promoCode.length > 0 &&
                <Flexbox flexDirection="row" justifyContent="space-between" alignItems="center">

                    <span style={{fontSize: 12, color: "#5F66F9"}}>
                        SUBTOTAL PRICE:
                    </span>
                    {this.renderSubtotalPrice()}

                </Flexbox>
                }

                {this.renderPromoCode()}

                <Flexbox flexDirection="row" justifyContent="space-between" alignItems="center">
                                <span style={{fontSize: 16, fontWeight: "bold"}}>
                                    TOTAL PRICE:
                                </span>
                    {this.renderTotalPrice()}
                </Flexbox>
            </div>
        )
    };
    renderSubtotalPrice = () => {

        if (this.state.subtotal && !isNaN(this.state.subtotal)) {
            const subtotal = this.state.subtotal;
            return (
                <strong style={{fontSize: 16}}>
                    ${subtotal.toFixed(2)}
                </strong>
            );
        }

        return (
            <div className="loaderCRMExtension"/>
        );
    };
    renderTotalPrice = () => {

        if (this.state.total && !isNaN(this.state.total)) {
            const total = this.state.total;
            return (
                <strong style={{fontSize: 16}}>
                    ${total.toFixed(2)}
                </strong>
            );
        }
        return (
            <div className="loaderCRMExtension"/>
        );
    };
    renderLineItems = () => {


        if (this.state.lineItems) {
            return this.state.lineItems.map((item, index) => {

                const value = item.value;

                return (
                    <Flexbox
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{padding: "5px 10px"}}
                        key={index}
                    >
                        <div>
                            {item.label}
                        </div>
                        <div>
                            - ${value.toFixed(2)}
                        </div>
                    </Flexbox>

                )
            });
        }


        return (
            <Flexbox justifyContent="center" alignItems="center" style={{width: "100%", marginBottom: 20}}>
                <div className="loaderCRMExtension"/>
            </Flexbox>

        );


        /*

                                {isRenewal &&
                        <Flexbox flexDirection="row" justifyContent="space-between" alignItems="center"
                                 style={{padding: "5px 10px"}}>
                            <div>
                                Renewal Discount
                            </div>
                            <div>
                                - $100.00
                            </div>
                        </Flexbox>
                        }

                        {isBrokerComp &&
                        <Flexbox flexDirection="row" justifyContent="space-between" alignItems="center"
                                 style={{padding: "5px 10px"}}>
                            <div>
                                Broker Comp *
                            </div>
                            <div>
                                - $100.00
                            </div>
                        </Flexbox>
                        }
         */
    };

    render() {

        return (
            <Modal
                size="tiny"
                trigger={
                    <Button primary
                            onClick={this.openModal}
                            style={{align: 'center'}}
                    >
                        Extend Subscription
                    </Button>
                }
                open={this.state.open}
                onClose={() => this.setState({open: false})}
            >

                <Modal.Content style={{backgroundColor: "#F4F8FC", padding: 0}}>
                    <Flexbox flexDirection="column" style={{backgroundColor: "#5F66F9", color: "#fff", padding: 20}}>
                        <h1>
                            Purchase Subscription
                        </h1>
                    </Flexbox>

                    <div style={{padding: 20}}>

                        <Flexbox flexDirection="row" justifyContent="space-between" alignItems="center"
                                 style={{backgroundColor: "#e7ebef", padding: 15, borderRadius: 4}}>
                            <strong style={{fontSize: 16}}>
                                Details
                            </strong>
                            <span style={{fontSize: 12, color: "#5F66F9"}}>
                                 PRICE
                            </span>
                        </Flexbox>

                        <Flexbox flexDirection="row" justifyContent="space-between" alignItems="center"
                                 style={{padding: "10px 10px"}}>
                            <div>
                                <b>AgMarket.App Subscription Plan: 1 YR</b>
                            </div>
                            {this.renderBaseCost()}
                        </Flexbox>

                        {this.renderLineItems()}

                        {this.renderTotalsBox()}
                    </div>

                    {this.renderPayStub()}

                </Modal.Content>
            </ Modal>
        )
    }

    renderPromoCode = () => {
        //const isBroker = this.props.user.groupID === AuthGroup.AccountManager;

        return (
            <Flexbox flexDirection="row" justifyContent="space-between" alignItems="center"
                     style={{padding: "0px 0px 10px 0px"}}>
                {this.state.showPromoCodeEntry ?
                    <Flexbox flexDirection="column" style={{width: "100%"}}>
                        <Flexbox flexDirection="row" style={{width: "100%"}}
                                 justifyContent="space-between"
                                 alignItems="center">
                            <input
                                className="StripeField"
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                style={{maxWidth: 200}}
                                name="promocode"
                                placeholder="Promo Code"
                                type="text"
                                value={this.state.promoCode}
                                onChange={(evt) => this.setState({promoCode: evt.target.value})}
                            />
                            <Flexbox flexDirection="row" alignItems="center">
                                                <span style={{
                                                    color: "#5F66F9",
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    marginRight: 10,
                                                    marginLeft: 10
                                                }} onClick={() => {
                                                    this.setState({
                                                        applyingPromoCode: false,
                                                        promoCode: "",
                                                        promoCodeError: null,
                                                        showPromoCodeEntry: false
                                                    })
                                                }}
                                                >
                                                cancel
                                            </span>
                                <Button
                                    onClick={this.applyPromoCode}
                                    loading={this.state.applyingPromoCode}
                                    disabled={this.state.applyingPromoCode}
                                >
                                    Apply
                                </Button>
                            </Flexbox>

                        </Flexbox>
                        {this.state.promoCodeError &&
                        <span className="errorMsg">
                                            <sup>*</sup> {this.state.promoCodeError}
                                        </span>
                        }

                    </Flexbox>

                    :

                    this.state.promoCode && this.state.percentOff ?

                        <Flexbox
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{padding: "5px 0px", width: "100%"}}
                        >
                            <div>
                                {this.state.percentOff}% off
                            </div>
                            <div>
                                - ${Math.abs(this.state.total - this.state.subtotal).toFixed(2)}
                            </div>
                        </Flexbox>

                        :


                        <Flexbox flexDirection="row" justifyContent="flex-end" flexGrow={1}
                                 style={{
                                     color: "#5F66F9",
                                     textDecoration: "underline",
                                     cursor: "pointer",
                                     fontSize: 12
                                 }}
                                 onClick={() => this.setState({showPromoCodeEntry: true})}
                        >
                            Add Promo Code
                        </Flexbox>
                }

            </Flexbox>
        )
    };

    renderPayStub = () => {
        const isBroker = this.props.user.groupID === AuthGroup.AccountManager;

        return (
            <div style={{borderTop: "2px dotted #c5c9cd", padding: 20}}>
                <p style={{paddingLeft: 10}}>
                    Card Details
                </p>

                <div style={{marginTop: 20}}>
                    <label style={{color: "#A9B2BE"}}>
                        <input
                            className="StripeField"
                            name="name"
                            type="text"
                            value={this.state.cardHolderName}
                            onChange={(evt) => this.setState({cardHolderName: evt.target.value})}
                            required
                        />
                    </label>
                </div>

                <div style={{marginTop: 20, marginBottom: 20}}>
                    <label style={{color: "#A9B2BE"}}>
                        <CardElement onChange={this.changeHandler}
                                     options={{
                                         fonts: [
                                             {
                                                 family: 'Open Sans',
                                                 style: 'normal',
                                             },
                                             {
                                                 family: "Helvetica Neue"
                                             },
                                             {
                                                 family: "Helvetica"
                                             },
                                             {
                                                 family: "sans-seriff"
                                             }
                                         ]
                                     }}
                                     style={{
                                         base: {
                                             fontSize: '18px',
                                             backgroundColor: "#FFF",
                                             fontFamily: 'Open Sans, Helvetica Neue, Helvetica, sans-serif',
                                         }
                                     }}/>
                    </label>
                </div>

                {isBroker &&
                <p style={{color: "#9F9F9F"}}>
                    * Partner Revenue splitting disabled. All proceeds will go to Linx Systems.
                </p>
                }

                <Flexbox justifyContent="flex-end" flexDirection="row">
                    <Button
                        primary
                        onClick={this.submitPurchase}
                        disabled={this.state.isPaying}
                        loading={this.state.isPaying}
                    >
                        Pay
                    </Button>
                </Flexbox>
            </div>
        )
    }
}

const ExtendSubscriptionModalWithStripe = injectStripe(ExtendSubscriptionModal);
export default ExtendSubscriptionModalWithStripe;

