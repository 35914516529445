import createReducer from '../lib/createReducer'
import * as types from '../actions/types'


export const licenses = createReducer({ list: [] }, {

  // [types.SET_ARTICLES](state, action) {
  //   let newState = Object.assign({}, state);
  //   if(action.articles !== null) {
  //     newState.articles = action.articles;
  //   } else {
  //     newState.articles = [];
  //   }
  //   return newState;
  // },

    [types.SET_LICENSES](state, action) {
    let newState = Object.assign({}, state);
    if(action.licenses !== null) {
      newState.licenses = action.licenses;
    } else {
      newState.licenses = [];
    }
    return newState;
  },

});
