//-------------------------------------------------------------------------------------------------
// Create a singleton instance of the bugsnag client so we don't have to duplicate our configuration
// anywhere.
//-------------------------------------------------------------------------------------------------
// https://docs.bugsnag.com/platforms/react-native/#basic-configuration
// import { Client, Configuration } from 'bugsnag-react-native';
// //const client = new Client();
// //-------------------------------------------------------------------------------------------------
// //export default client;
//

import bugsnag from 'bugsnag-js'


export default (() => {

  class MyBugSnag {
    // Private constructor
    constructor() {
      const bugsnagClient = bugsnag('5348b88eac79ea126869e48de81fb930');
      this.client = bugsnagClient;
    }
  }

  return {
    // Public static factory method
    getInstance() {
      if (!MyBugSnag.instance) {
        MyBugSnag.instance = new MyBugSnag();
      }
      return MyBugSnag.instance;
    }
  };

})();
