import React from 'react';

let helpButton = require('dealer/assets/help.svg');

export const BasicTourSteps = [
    {
        target: '[data-tut="reactour__topbar"]',
        disableBeacon: true,
        title: "Welcome to the AgMarket Dealer Dashboard",
        content: (
            <div>
                <div style={{textAlign: "left"}}>
                    If you ever get lost, you can click on our logo to get back to the main screen.
                </div>
                <br/>
                <div style={{textAlign: "left"}}>
                    There are help buttons in the app that you can click to get more information.
                </div>
                <br/>
                <img src={helpButton} alt="Help Button"/>
            </div>
        ),
    },
    {
        target: '[data-tut="reactour__sidebar"]',
        isFixed: true,
        placement: 'right',
        title: 'Sidebar Navigation',
        content: (
            <div>
                <div style={{textAlign: "left"}}>
                    The <span style={{fontWeight: 'bold'}}>Customers</span> section will show a list of all of the
                    customers added to the system using your promo codes.
                </div>
                <br/>
                <div style={{textAlign: "left"}}>
                    The <span style={{fontWeight: 'bold'}}>Licenses</span> section lets you view and manage your license
                    blocks. Here you can create and disable promo codes for each of your licenses.
                </div>
                <br/>
                <div style={{textAlign: "left"}}>
                    The <span style={{fontWeight: 'bold'}}>Edit Landing Page</span> section will allow you to build your
                    custom Co-Branded Landing Page that your customers will use to sign up for AgMarket App
                </div>
                <br/>
                <div style={{textAlign: "left"}}>
                    Refer to the <span style={{fontWeight: 'bold'}}>Help</span> section to find answers to frequently
                    asked questions.
                </div>
                <img src={helpButton} alt="Help Button"/>
            </div>
        ),
    },
    // {
    //     target: '[data-tut="reactour__licenses"]',
    //     isFixed: true,
    //     placement: 'right',
    //     content: 'The Licenses section lets you view and manage your license blocks. Here you can create and disable promo codes for each of your licenses.',
    // },
    // {
    //     target: '[data-tut="reactour__editlandingpage"]',
    //     isFixed: true,
    //     content: 'The Edit Landing Page section will allow you to build your custom Co-Branded Landing Page that your customers will use to sign up for AgMarket App',
    // },
    // {
    //     target: '[data-tut="reactour__help"]',
    //     isFixed: true,
    //     content: 'Refer to the Help section to find answers to frequently asked questions.',
    // },
    // {
    //     target: '[data-tut="reactour__logout"]',
    //     isFixed: true,
    //     content: 'Use this button to logout of your AgMarket App Dealer Account.',
    // }
]
