import React, {Component} from 'react';
import LoginButton from '../components/LoginButton'
import {Grid, Image} from 'semantic-ui-react'
import {Link} from 'react-router-dom';
import bennet_logo from '../assets/ag_market_banner.png'
import {BACKGROUND_BLUE} from "../../Constants";

class TopBar extends Component {


    render() {
        return (
            <Grid columns={2} align='center' style={{
                position: 'fixed',
                width: '100%',
                height: '100px',
                backgroundColor: BACKGROUND_BLUE,
                padding: '10px',
                margin: '0px',
                zIndex: 2
            }}>
                <Grid.Row>
                    <Grid.Column align='left' style={{alignSelf: 'center'}}>
                        <Link to="/">
                            <Image src={bennet_logo} style={{maxHeight: '60px'}}/>
                        </Link>

                    </Grid.Column>
                    <Grid.Column align='right' style={{justifyContent: 'right', alignSelf: 'center'}}>
                        <LoginButton/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default TopBar;

