// initialize bugsnag ASAP, before other imports
import bugsnag from "bugsnag-js";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import createPlugin from "bugsnag-react";

import Config from "./Config";

import { AuthGroup } from "./Constants";

import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./viewerapp/store";
import { ActionCreators } from "./viewerapp/actions";
import { validate } from "./viewerapp/actions/storydynamics";

import ErrorScreen from "./viewerapp/containers/ErrorScreen";

import MyErrorBoundary from "./viewerapp/components/MyErrorBoundary";
import LazyLoadingComponentPlaceholder from "./viewerapp/components/LazyLoadingComponentPlaceholder";

import { withInnercastCore } from "innercast-core-js";

import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";

// Firebase App (the core Firebase SDK) is always required and must be listed first
// import * as firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// import "firebase/analytics";

const MyFarmReportsScreen = React.lazy(() =>
    import(/* webpackChunkName: "MyFarmReportsScreen" */ "./viewerapp/containers/MyFarmReportsScreen"),
);
const NewReportWizardScreen = React.lazy(() =>
    import(/* webpackChunkName: "NewReportWizardScreen" */ "./viewerapp/containers/reports/NewReportWizardScreen"),
);

const METADATA = require("./metadata.json");

const APP_VERSION = METADATA.displayName;

const RouterRenderer = withRouter((props) => props.render());

/*
IE8/9/10/11 does not support ES6 Promise, run this to polyfill the global environment at the beginning of your application.
*/
require("es6-promise").polyfill();

const bugsnagClient = bugsnag({
    apiKey: "5348b88eac79ea126869e48de81fb930",
    notifyReleaseStages: ["production", "staging"],
    logger: null,
    consoleBreadcrumbsEnabled: false,
});
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // dev code
    console.log("development mode...");
    bugsnagClient.app.releaseStage = "development";
} else {
    // production code
    console.log("production mode...");
    console.log = () => {};
    bugsnagClient.app.releaseStage = "production";
}
bugsnagClient.app.version = APP_VERSION;

//bugsnagClient.notify(new Error("test error"));

// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = bugsnagClient.use(createPlugin(React));

let dataURL = window.location.origin;

if (dataURL.indexOf(".internal.linxsystems.com") >= 0) {
    // dataURL = "https://www.agmarket.app";
    dataURL = "https://pc-sam-backend-agmarket.internal.linxsystems.com";
    // dataURL = "https://mb-avash-backend-agmarket.internal.linxsystems.com";
    // dataURL = "https://vpn-avash-backend-agmarket.internal.linxsystems.com";
    // dataURL = "https://beta.agmarket.app";
}

Config.HOST = dataURL;
Config.DEBUG = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

console.log("Config.HOST", Config.HOST);

bugsnagClient.user = {
    ...bugsnagClient.user,
    // name: username,
    // id: userID
};

if (window.location.pathname.toString().toLowerCase() === "/gatekeeper/v/mobilereports") {
    const queryString = require("query-string-es5");

    store.dispatch(validate());

    const UID = queryString.parse(window.location.search).uid;
    const USERNAME = queryString.parse(window.location.search).username;

    const renderMyFarmReportsScreen = (props) => {
        // const allProps = Object.assign(props);
        return <MyFarmReportsScreen isMobileWebview={true} userID={UID} username={USERNAME} dataURL={dataURL} {...props} />;
    };

    const renderNewReportWizard = (props) => {
        // const allProps = Object.assign(props, this.props);
        return (
            <MyErrorBoundary>
                <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                    <NewReportWizardScreen isMobileWebview={true} userID={UID} username={USERNAME} {...props} />
                </Suspense>
            </MyErrorBoundary>
        );
    };

    ReactDOM.render(
        <ErrorBoundary FallbackComponent={ErrorScreen}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <MyErrorBoundary>
                        <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                            <Router>
                                <RouterRenderer
                                    render={() => (
                                        <Switch>
                                            <Route path="/app/myfarm/reports/new" render={renderNewReportWizard} />
                                            <Route path="/gatekeeper/v/mobilereports" render={renderMyFarmReportsScreen} />
                                            <Route render={renderMyFarmReportsScreen} />
                                        </Switch>
                                    )}
                                />
                            </Router>
                        </Suspense>
                    </MyErrorBoundary>
                </PersistGate>
            </Provider>
        </ErrorBoundary>,
        document.getElementById("root"),
    );
} else {
    // store.dispatch()
    ReactDOM.render(
        withInnercastCore(
            <ErrorBoundary FallbackComponent={ErrorScreen}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <App dataURL={dataURL} />
                    </PersistGate>
                </Provider>
            </ErrorBoundary>,
            {
                HOST: Config.HOST,
                validateEndpoint: "/API/v2/validate",
                basename: "/gatekeeper/v",
                AuthGroup: AuthGroup,
                Theme: "AgMarket",
                DEBUG: Config.DEBUG,
                store: store,
                ActionCreators: ActionCreators,
            },
        ),
        document.getElementById("root"),
    );
}

registerServiceWorker();
