import createReducer from '../lib/createReducer'
import * as types from '../actions/types'


export const dealerdetails = createReducer({
    details: {
        company_name: "",
        landing_page_url: "",
        phone_number: "",
        website: "",
        abstract: "",
        facebook_url: "",
        twitter_url: "",
        linked_in_url: "",
        user_id: "",
        username: "",
        logo_image_url: "",
        graphic_image_url: ""
    }
}, {

    [types.SET_DEALER_DETAILS](state, action) {
        let newState = Object.assign({}, state);
        // console.log('HERE', action)
        if (action.dealerDetails !== null) {
            newState.details = action.dealerDetails;
        } else {
            newState.details = {
                company_name: "",
                landing_page_url: "",
                phone_number: "",
                website: "",
                abstract: "",
                facebook_url: "",
                twitter_url: "",
                linked_in_url: "",
                user_id: "",
                username: "",
                logo_image_url: "",
                graphic_image_url: ""
            };
        }
        return newState;
    },

});
