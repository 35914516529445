// STORY DYNAMICS
export const SET_ENDPOINT_URLS  = "SET_ENDPOINT_URLS";
export const SET_LOGIN          = "SET_LOGIN";
export const LOGOUT             = "LOGOUT";

// ERROR
export const SET_ERROR        = "SET_ERROR";
export const CLEAR_ERROR      = "CLEAR_ERROR";


// Widget State
export const DETECTED_POSSIBLE_ORIENTATION_CHANGE   = 'DETECTED_POSSIBLE_ORIENTATION_CHANGE';

export const TOGGLE_SIDEBAR_VISIBLE___WEB           = 'TOGGLE_SIDEBAR_VISIBLE___WEB';
export const SET_MAIN_TOUR_AS_WATCHED___WEB         = 'SET_MAIN_TOUR_AS_WATCHED___WEB';

// Licenses
export const SET_LICENSES = 'SET_LICENSES';

// Customers
export const SET_MY_USERS = 'SET_MY_USERS';

// Dealer Details
export const SET_DEALER_DETAILS = 'SET_DEALER_DETAILS';
