import React from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ActionCreators } from '../../actions'

import { Button, Modal } from 'semantic-ui-react';
import Markdown from 'markdown-to-jsx';
import raw from "raw.macro";

import { AuthGroup } from "../../../Constants";

class ChangeLogModal extends React.PureComponent {

    constructor(props) {
        super(props);


        this.state = {
            viewingPermissionLevel: this.props.authGroup
        }
    }

    _changeViewingLevel = () => {
        if (this.state.viewingPermissionLevel === this.props.authGroup) {
            this.setState({
                viewingPermissionLevel: AuthGroup.Viewer
            });
        } else {
            this.setState({
                viewingPermissionLevel: this.props.authGroup
            });
        }
    };

    render() {

        const isAM = this.props.authGroup === AuthGroup.AccountManager;
        const isPM = this.props.authGroup === AuthGroup.ProjectManager;
        const isAuthor = this.props.authGroup === AuthGroup.Author;

        let input = "";
        let titleSupplement = "";
        if (this.state.viewingPermissionLevel === AuthGroup.AccountManager ||
            this.state.viewingPermissionLevel === AuthGroup.ProjectManager ||
            this.state.viewingPermissionLevel === AuthGroup.Author) {
            input = raw('../../../CHANGELOG-Internal.md');
            titleSupplement = "- Admin";
        } else {
            input = raw('../../../CHANGELOG.md');
        }

        const switchBtn = isAM || isPM || isAuthor ? (
            <Button onClick={this._changeViewingLevel}>
                {this.state.viewingPermissionLevel === this.props.authGroup ? "Customer's   What's New? " : "Admin's   What's New?  "}
            </Button>
        ) : null;


        return (
            <Modal
                centered={false}
                size="large"
                open={this.props.isVisible}
                closeOnDocumentClick={true}
                onClose={this.props.toggleChangelogVisibility}
            >
                <Modal.Header>What's New {titleSupplement}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Markdown>
                            {input}
                        </Markdown>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    {switchBtn}
                    <Button primary onClick={this.props.toggleChangelogVisibility}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        isVisible: state.widgetstate.changelogVisible,
        authGroup: state.user.groupID
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLogModal);
