import React, {Component} from 'react';
import {Button} from 'semantic-ui-react'
import {BACKGROUND_BLUE} from "../../Constants";
import {Link} from 'react-router-dom';

class LoginButton extends Component {

    render() {
        return(
            <Link to="/login">
                <Button style={{backgroundColor: BACKGROUND_BLUE, color: 'white', border: '1px solid white'}} >Login</Button>
            </Link>
        )
    }
}

export default LoginButton;
