import createReducer from "../lib/createReducer";
import * as types from "../actions/types";

const DEFAULT = {
    username: null,
    uid: null,
    email: null,
    circle: null,
    fullName: null,
    deviceID: null,
    fcmToken: null,
    inviteExpiration: null,
    inviteCode: null,
    twoFA: null,
    groupID: null,
    isLoggedIn: false,
    token: null,
    profile_pic: null,
    subscription_expire_date: null,
    am_auth_token: null,
    pm_auth_token: null,
};

export const user = createReducer(DEFAULT, {
    [types.SET_IS_LOGGED_IN_STATUS](state, action) {
        let newState = Object.assign({}, state);
        newState.isLoggedIn = action.isLoggedIn;
        return newState;
    },

    // manage login
    [types.SET_LOGIN](state, action) {
        let newState = Object.assign({}, state);
        console.log(action.info);
        if (action.info !== null) {
            newState.username = action.info.username;
            newState.uid = action.info.uid;
            newState.email = action.info.email;
            newState.circle = action.info.circle;
            newState.groupID = action.info.group_id;
            newState.fullName = action.info.full_name;
            newState.token = action.info.token;
            newState.profile_pic = action.info.profile_picture;
            newState.isLoggedIn = true;
            newState.subscription_expire_date = action.info.subscription_expire_date;
        } else {
            newState.username = null;
            newState.uid = null;
            newState.email = null;
            newState.circle = null;
            newState.fullName = null;
            newState.groupID = null;
            newState.token = null;
            newState.profile_pic = null;
            newState.isLoggedIn = false;
            newState.subscription_expire_date = null;
        }
        return newState;
    },

    [types.LOGOUT](state, action) {
        let newState = Object.assign({}, state);
        newState.username = null;
        newState.uid = null;
        newState.email = null;
        newState.circle = null;
        newState.fullName = null;
        newState.groupID = null;
        newState.token = null;
        newState.isLoggedIn = false;
        newState.profile_pic = null;
        newState.subscription_expire_date = null;
        return newState;
    },

    [types.CHANGE_MY_DETAILS](state, action) {
        let newState = Object.assign({}, state);
        if (action.data !== null) {
            if (action.data.fullName) {
                newState.fullName = action.data.fullName;
            }
            if (action.data.username) {
                newState.username = action.data.username;
            }
            if (action.data.email) {
                newState.email = action.data.email;
            }
            if (action.data.circle) {
                newState.circle = action.data.circle;
            }
            if (action.data.groupID) {
                newState.groupID = action.data.groupID;
            }
            if (action.data.avatar_url) {
                newState.profile_pic = action.data.avatar_url;
            }
            if (action.data.subscription_expire_date) {
                newState.subscription_expire_date = action.data.subscription_expire_date;
            }
        }
        return newState;
    },

    [types.SET_AM_COOKIE](state, action) {
        let newState = Object.assign({}, state);
        newState.am_auth_token = action.am_auth_token;
        return newState;
    },

    [types.SET_PM_COOKIE](state, action) {
        let newState = Object.assign({}, state);
        newState.pm_auth_token = action.pm_auth_token;
        return newState;
    },
});
