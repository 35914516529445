import createReducer from "../lib/createReducer";
import * as types from "../actions/types";
import * as Constants from "Constants";

const ORIENTATION_PORTRAIT = 0;
//const ORIENTATION_LANDSCAPE = 1;

const FILTER_GRAIN_BID_CORN_INDEX = 0;
const FILTER_GRAIN_BID_SOYBEAN_INDEX = 1;
const FILTER_GRAIN_BID_WHEAT_INDEX = 2;
const FILTER_GRAIN_BID_OTHER_INDEX = 3;

const DEFAULTS = {
    outlookIsVisible: true,
    bidsPinned: [],
    orientation: ORIENTATION_PORTRAIT,
    grainBidFiltersVisible: false,
    grainBidFilterCommodities: [
        FILTER_GRAIN_BID_CORN_INDEX,
        FILTER_GRAIN_BID_SOYBEAN_INDEX,
        FILTER_GRAIN_BID_WHEAT_INDEX,
        FILTER_GRAIN_BID_OTHER_INDEX,
    ], // MOBILE version
    grainBidCommodityFilters: ["corn", "soybean", "wheat", "other"], // WEB version
    grainBidFilterExcludesDeliveryMonths: [],
    futuresBoardSelectedCommodityIndex: 0,
    selectedTechnicalOverlays: [],
    sidebarVisible: true,
    mainTourHasBeenWatched: null,
    mySelectedFarmYear: new Date().getFullYear(),
    activeMyFarmCommodity: Constants.CORN_TYPE,
    editorToolbarDisplay: Constants.ICON_AND_TEXT,
    co_branded_image: null,
    co_branded_website_url: null,
    shown_co_brand: false,
    changelogVisible: false,
};

export const widgetstate = createReducer(DEFAULTS, {
    [types.RESET_TO_DEFAULTS]() {
        return DEFAULTS;
    },

    // persist that the main tour has been viewed so we don't keep
    // showing it to the user and annoying them.
    [types.SET_MAIN_TOUR_AS_WATCHED___WEB](state, action) {
        let newState = Object.assign({}, state);
        newState.mainTourHasBeenWatched = action.has_viewed_tutorial;
        return newState;
    },

    [types.TOGGLE_OUTLOOK_VISIBILITY](state) {
        // clone the existing state
        let newState = Object.assign({}, state);
        newState.outlookIsVisible = !state.outlookIsVisible;
        return newState;
    },

    [types.TOGGLE_SIDEBAR_VISIBLE___WEB](state, action) {
        // clone the existing state
        let newState = Object.assign({}, state);
        newState.sidebarVisible = action.isVisible;
        return newState;
    },

    [types.DETECTED_POSSIBLE_ORIENTATION_CHANGE](state, action) {
        // clone the existing state
        let newState = Object.assign({}, state);
        newState.orientation = action.isLandscapeOrientation;
        return newState;
    },

    /**
     Controls whether the grain bid filtering subview is visible or not
     */
    [types.TOGGLE_GRAIN_BID_FILTERING_VIEW](state) {
        // clone the existing state
        let newState = Object.assign({}, state);
        newState.grainBidFiltersVisible = !state.grainBidFiltersVisible;
        return newState;
    },

    /**
     Lets users filter out different commodities from the Cash Bids table
     // MOBILE API
     */
    [types.SET_FILTER_FOR_COMMODITY](state, action) {
        // clone the existing state
        let newState = Object.assign({}, state);

        const index = action.index;

        if (newState.grainBidFilterCommodities.includes(index)) {
            // filter out this commodity type
            newState.grainBidFilterCommodities = newState.grainBidFilterCommodities.filter((i) => i !== index);
        } else {
            // add this commodity to search results
            newState.grainBidFilterCommodities = [].concat(newState.grainBidFilterCommodities, index);
        }
        return newState;
    },

    [types.SET_FUTURE_BOARD_COMMODITY_TYPE](state, action) {
        let newState = Object.assign({}, state);
        newState.futuresBoardSelectedCommodityIndex = action.index;
        return newState;
    },

    [types.SET_MY_SELECTED_FARM_YEAR](state, action) {
        let newState = Object.assign({}, state);
        newState.mySelectedFarmYear = action.year;
        return newState;
    },

    [types.SET_ACTIVE_MY_FARM_COMMODITY](state, action) {
        let newState = Object.assign({}, state);
        newState.activeMyFarmCommodity = action.commodity;
        return newState;
    },

    [types.SET_EDITOR_TOOLBAR_DISPLAY](state, action) {
        let newState = Object.assign({}, state);
        newState.editorToolbarDisplay = action.display;
        return newState;
    },

    [types.SET_CO_BRANDED_INFO](state, action) {
        let newState = Object.assign({}, state);
        console.log(action.info);
        if (action.info !== null) {
            newState.co_branded_image = action.info.co_branded_image;
            newState.co_branded_website_url = action.info.co_branded_website_url;
        } else {
            newState.co_branded_image = null;
            newState.co_branded_website_url = null;
        }
        return newState;
    },

    [types.SHOWN_CO_BRAND](state) {
        let newState = Object.assign({}, state);
        newState.shown_co_brand = true;
        return newState;
    },

    [types.TOGGLE_CHANGELOG_VISIBILITY](state) {
        let newState = Object.assign({}, state);
        newState.changelogVisible = !newState.changelogVisible;
        return newState;
    },

    [types.REMOVE_REDUX_PINNED_BID](state, action) {
        let newState = Object.assign({}, state);
        if (action.bid_id !== null) {
            let bidsPinned = newState.bidsPinned;
            bidsPinned = bidsPinned.filter((id) => id !== action.bid_id);
            newState.bidsPinned = bidsPinned;
        }
        return newState;
    },
});
