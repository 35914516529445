import React, { Component } from "react";
import Flexbox from "flexbox-react";
import ButtonOpacity from "./ButtonOpacity";
import ButtonOpacitySVG from "./ButtonOpacitySVG";

import { isIE } from "react-device-detect";

import logo from "../assets/Ag_Market_Banner.png";

import { Link } from "react-router-dom";

import "../styles/Components.css";

class TopBar extends Component {
    render() {
        const IS_AUTHENTICATED = true; //(this.props.user) ? this.props.user.isAuthenticated : false;

        const menu = IS_AUTHENTICATED ? (
            <ButtonOpacity onClick={() => this.props.didAskForMenu(true)} iconName="menu_button" title="Show sidebar menu" />
        ) : null;

        const help_button = isIE ? null : (
            <ButtonOpacitySVG
                style={{ cursor: "pointer" }}
                onClick={this.props.showMainTour}
                iconName="tutorial"
                title="Show Standard Tutorial"
            />
        );

        //  console.log(this.props);

        const topRight = (
            /*this.props.user !== null && this.props.user.uid ? <TopMenu {...this.props} /> :*/ <div
                style={{ fontSize: 24, fontWeight: "bold" }}>
                {help_button}
            </div>
        );

        return (
            <Flexbox flexDirection="row" justifyContent="space-between" alignItems="center" className="dealer-topbar">
                <div>{this.props.menuIsVisible ? "" : menu}</div>
                <Link to="/">
                    <img data-tut="reactour__topbar" src={logo} alt="AgMarket.Net" height={40} />
                </Link>
                {topRight}
            </Flexbox>
        );
    }
}

export default TopBar;
