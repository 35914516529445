import React, {Component} from 'react';

import 'signup/css/App.css';
import {Route, Switch} from 'react-router-dom'

import TopBar from '../../signup/components/TopBar'
import PublicBase from './PublicBase'
import DealerBase from './DealerBase'

class Signup extends Component {

    renderDealerBase = () => {
        return (<DealerBase/>)
    };

    renderPublicBase = () => {
        return (<PublicBase/>)
    };

    render() {

        return (
            <div className="App">
                <div style={{flex: '0 1 auto'}}>
                    <TopBar/>
                </div>
                <Switch>
                    <Route path='/dealer' render={this.renderDealerBase}/>
                    <Route path='/' render={this.renderPublicBase}/>
                    <Route render={this.renderPublicBase}/>
                </Switch>
            </div>
        );
    }
}

export default Signup;
