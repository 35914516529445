import * as types from './types';
import * as Constants from 'Constants';
import {executeBasicNetworkCall} from "../../lib/Utils";

export function deviceIsOrientedInMode(isLandscapeOrientation) {
    return {
        type: types.DETECTED_POSSIBLE_ORIENTATION_CHANGE,
        isLandscapeOrientation
    }
}

export function toggleGrainBidFilteringView() {
    return {
        type: types.TOGGLE_GRAIN_BID_FILTERING_VIEW
    }
}

// mobile api
export function setFilterForCommodityIndex(index) {
    return {
        type: types.SET_FILTER_FOR_COMMODITY,
        index
    }
}

export function setSelectedFuturesBoardCommodityType(commodityIdx) {
    return {
        type: types.SET_FUTURE_BOARD_COMMODITY_TYPE,
        index: commodityIdx
    }
}

export function toggleOutlookVisibility() {
    return {
        type: types.TOGGLE_OUTLOOK_VISIBILITY
    }
}

export function toggleSidebarVisible(isVisible = true) {
    return {
        type: types.TOGGLE_SIDEBAR_VISIBLE___WEB,
        isVisible
    }
}

export function setMainTourAsWatched() {
    return (dispatch, getState) => {

        console.log('SETTING MAIN TOUR AS WATCHED')

        executeBasicNetworkCall(
            "set_tutorial_as_watched",
            undefined,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);

                    if (x.error) {
                        console.log(x.message)
                    } else {
                        console.log(x.message)
                    }
                }
            },
            error => {
                console.log(error);
            }
        );

        //TODO clear all redux states here!!!
        dispatch(setTourAsWatched());
    }
}

function setTourAsWatched(has_viewed_tutorial = true) {
    return {
        type: types.SET_MAIN_TOUR_AS_WATCHED___WEB,
        has_viewed_tutorial
    }
}

export function setMySelectedFarmYear(year) {
    return {
        type: types.SET_MY_SELECTED_FARM_YEAR,
        year
    }
}

export function setActiveMyFarmCommodity(commodity) {
    return {
        type: types.SET_ACTIVE_MY_FARM_COMMODITY,
        commodity
    }
}

export function setEditorToolbarDisplay(display = Constants.ICON_AND_TEXT) {
    return {
        type: types.SET_EDITOR_TOOLBAR_DISPLAY,
        display
    };
}

export function toggleChangelogVisibility() {
    return {
        type: types.TOGGLE_CHANGELOG_VISIBILITY
    }
}

export function removeReduxPinnedBid(bid_id) {
    return {
        type: types.REMOVE_REDUX_PINNED_BID,
        bid_id,
    }
}