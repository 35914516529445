class ConfigManager {

    static instance = null;

    static createInstance() {
        const object = new ConfigManager();
        return object;
    }

    static getInstance() {
        if (!ConfigManager.instance) {
            ConfigManager.instance = ConfigManager.createInstance();
        }
        return ConfigManager.instance;
    }
}

const instance = ConfigManager.getInstance();
export default instance;
