import React, { Component } from 'react';
import '../../../App.css';
import { NavLink } from 'react-router-dom'
import Flexbox from 'flexbox-react';
import {MenuBlue, BackgroundBlue} from '../../styles';

import ButtonOpacity from '../components/ButtonOpacity';

class SidebarGroup extends Component {

  constructor(props) {
    super(props);

    this.state = { open: true };
  }

  render() {


    const icon2x = require('../../assets/'+this.props.iconName+'@2x.png');
    const icon3x = require('../../assets/'+this.props.iconName+'@3x.png');

    let children = this.props.children ? (<Flexbox flexDirection="column" className="Submenu">{this.props.children}</Flexbox>) : null;

    let collapseIcon = "expand_arrow";
    if (this.state.open) {
      collapseIcon = "collapse_arrow";
    } else {
      children = null;
    }

    return (
      <Flexbox flexDirection="column" className="SidebarGroup">
        <NavLink
          onClick={this.props.onClick}
          exact to={this.props.link}
          style={{ textDecoration: 'none', backgroundColor: MenuBlue }}
          activeStyle={{ backgroundColor: BackgroundBlue }}>
          <Flexbox data-tut={this.props.dataTut} flexDirection="row"  alignItems="center" style={{color: 'white', textDecoration: 'none', paddingLeft: 10, paddingTop: 10, paddingBottom: 10, paddingRight: 20}} >
            <ButtonOpacity
              onClick={() => this.setState({ open : !this.state.open })}
              iconName={collapseIcon}
              title="Expand or collapse section"
            />
            <img
              alt=''
              style={{marginLeft: 5}}
              src={require("../../assets/"+this.props.iconName+".png")}
              srcSet={`
                ${icon2x} 2x,
                ${icon3x} 3x
              `}
            />
            <div style={{marginLeft: 10}}>
              {this.props.title}
            </div>
          </Flexbox>
        </NavLink>
        {children}
      </Flexbox>
    );
  }
}

export default SidebarGroup;
