// import * as types from './types';
// import bugsnagClient from '../../lib/bugsnag';
import {executeBasicNetworkCall} from "../../lib/Utils";

export function getSubscriptionCost(forUID, callback = () => {
}) {
    return (dispatch, getState) => {

        const params = JSON.stringify({
            uid: forUID
        });

        executeBasicNetworkCall(
            "get_subscription_cost_crm",
            params,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);
                    if (x.error) {
                        console.log(x.message)
                    } else {
                        if (x.data) {
                            callback(true, x.data)
                        } else {
                            callback(false, x.error)
                        }
                    }
                }
            },
            error => {
                callback(false, error);
            },
            false,
            status => {
                callback(false, status)
            }
        );
    };
}

export function applyPromoCode(promoCode, forUID, callback = () => {
}) {
    return (dispatch, getState) => {

        const params = JSON.stringify({
            uid: forUID,
            promo_code: promoCode
        });

        executeBasicNetworkCall(
            "promo_code_crm",
            params,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);
                    if (x.error) {
                        callback(false, x.message)
                    } else {
                        if (x.data) {
                            callback(true, x.data, x.subscription_expire_date)
                        } else {
                            callback(false, "Server responded back strangely.  Code: 253")
                        }
                    }
                }
            },
            error => {
                callback(false, error);
            },
            false,
            status => {
                callback(false, status)
            }
        );
    };
}

export function extendMembership(forUID, stripeToken, promoCode, callback = () => {
}) {
    return (dispatch, getState) => {

        const params = JSON.stringify({
            uid: forUID,
            token: stripeToken,
            promo_code: promoCode
        });

        console.log(params);

        executeBasicNetworkCall(
            "extend_membership_crm",
            params,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);
                    if (x.error) {
                        callback(false, x.error)
                    } else {
                        callback(true, x.subscription_expire_date)
                    }
                }
            },
            error => {
                callback(false, error);
            },
            false,
            status => {
                callback(false, status)
            }
        );
    };
}

export function amGiveTwoWeekTrial(uid, callback = () => {
}) {
    return (dispatch, getState) => {

        const params = JSON.stringify({
            uid: uid
        });

        console.log(params);

        executeBasicNetworkCall(
            "am_give_two_week_trial",
            params,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);
                    if (x.error) {
                        console.log(x.message)
                        callback()
                    } else {
                        callback()
                    }
                }
            },
            error => {
                callback();
            },
            false,
            status => {
                callback()
            }
        );
    };
}
