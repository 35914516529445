import createReducer from '../lib/createReducer'
import * as types from '../actions/types'

const ORIENTATION_PORTRAIT = 0;
//const ORIENTATION_LANDSCAPE = 1;


export const dealerwidgetstate = createReducer({
    orientation: ORIENTATION_PORTRAIT,
    sidebarVisible: true,
    mainTourHasBeenWatched: false,
  }, {

    // persist that the main tour has been viewed so we don't keep
    // showing it to the user and annoying them.
  [types.SET_MAIN_TOUR_AS_WATCHED___WEB](state, action) {
    let newState = Object.assign({}, state);
    newState.mainTourHasBeenWatched = true;
    return newState;
  },

  [types.TOGGLE_SIDEBAR_VISIBLE___WEB](state, action) {

    // clone the existing state
    let newState = Object.assign({}, state);
    newState.sidebarVisible = action.isVisible;
    return newState;
  },

  [types.DETECTED_POSSIBLE_ORIENTATION_CHANGE](state, action) {
    // clone the existing state
    let newState = Object.assign({}, state);
    newState.orientation = action.isLandscapeOrientation;
    return newState;
  },

});
