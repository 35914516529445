import {HOST} from "Constants";
import futch from "../../lib/futch";
// import * as MessageActions from "./messages";

// export function uploadImage(image, tags, note, uid, success) {
export function uploadImage(
    image,
    uid = 2,
    note = "",
    tags = [],
    progressCallback,
    completionCallback
) {
    return (dispatch, getState) => {

        // Do some basic validation
        if (!image || image === null) {
            return;
        }
        const uuidv4 = require("uuid/v4");

        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const payload_token_identifier =
            uid + "_" + year + month + day + "_" + uuidv4();
        // finish payload token generation

        // build image & thumbnail names
        const imageName = "image_" + uid + "_" + uuidv4();
        const thumbnailName = "thumbnail_" + uid + "_" + uuidv4();

        // BUILD THE API URL
        const ENDPOINT = "/API/v2/ImageUpload";
        const URL = `${HOST()}${ENDPOINT}`;

        console.log(URL);

        let mediaOneObj = {
            liked: 0,
            stored_at: "local",
            asset_type: 1,
            file_path: null,
            mime_type: image.type,
            date_liked: null,
            location: null,
            date_captured: today.toISOString(),
            id: 0,
            uploaded_file_name: imageName,
            content_checksum: null,
            azure_date: null //or azureData
        };

        let mediaArray = [mediaOneObj];

        let mediaRadix = {
            tags: tags,
            media: mediaArray,
            user_created_note: note, //"test note",
            release_date: "",
            burst_info: null,
            burst_found: 0,
            blog_found: 0,
            id: 0,
            composite_file_name: imageName,
            thumbnail_file_name: thumbnailName,
            date_received: null
        };


        let burstMediaRadixObject = {
            payload_token_identifier,
            media_radix: mediaRadix,
            version: 1,
            uid,
            image: null,
            thumbnail: null,
            image_name: imageName,
            thumbnail_name: thumbnailName
        };

        const data = new FormData();
        data.append("json_data", JSON.stringify([burstMediaRadixObject]));

        data.append(imageName, image);

        console.log(JSON.stringify([burstMediaRadixObject]));
        console.log("image", image);
        console.log(imageName);

        futch(
            URL,
            {
                method: "POST",
                body: data,
                headers: {}
            },
            progressEvent => {
                const progress = progressEvent.loaded / progressEvent.total;

                progressCallback(progress);
            }
        )
            .then(res => {

                console.log(res);

                if (res.status !== 500) {
                    const response = JSON.parse(res.response);

                    if (response) {
                        if (JSON.parse(res.response).error) {
                            completionCallback(
                                false,
                                "There was an error uploading your image"
                            );
                            console.warn(response.msg);
                            // dispatch(
                            //     MessageActions.setError(
                            //         "There was an error uploading your image"
                            //     )
                            // );
                        } else {
                            completionCallback(true, res.response);
                            // dispatch(
                            //     MessageActions.setSuccess(
                            //         "Your image was uploaded successfully"
                            //     )
                            // );
                        }
                    }
                }
            })
            .catch(err => {
                console.log(err);
                completionCallback(false, "There was an error uploading your image");
            });
    };
}

export function uploadVideo(
    video,
    uid=2,
    userNote = "",
    tags = [],
    progressCallback,
    completionCallback
) {
    return (dispatch, getState) => {


        console.log("uploading video...", video);

        // Do some basic validation
        if (!video || video === null) {
            return;
        }

        // build payload token identifier
        // const user = getState().user;
        // if (!user || user === null || !user.fullName) {
        //     return;
        // }
        // const usernameWithoutSpaces = user.fullName.split(" ").join("");

        const uuidv4 = require("uuid/v4");

        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const payload_token_identifier =
            uid + "_" + year + month + day + "_" + uuidv4();
        // finish payload token generation

        // build image & thumbnail names
        const videoName = "video_" + uid + "_" + uuidv4();
        const thumbnailName = "thumbnail" + uid + "_" + uuidv4();

        // BUILD THE API URL
        const URL = HOST() + "/API/v2/FileUpload";

        if (!video.type || video.type === null) {
            video.type = "video/mp4";
        }

        if (!video.name || video.name === null) {
            video.name = videoName + ".mp4";
        }

        let mediaOneObj = {
            liked: 0,
            stored_at: "local",
            asset_type: 2,
            file_path: null,
            mime_type: video.type, // CHECK THIS
            date_liked: null,
            location: null,
            date_captured: today.toISOString(),
            id: 0,
            uploaded_file_name: video.name, // CHECK THIS!
            content_checksum: null,
            azure_date: null //or azureData
        };

        let mediaArray = [mediaOneObj];

        let mediaRadix = {
            tags: tags,
            media: mediaArray,
            user_created_note: userNote, //"test note",
            release_date: "",
            burst_info: null,
            burst_found: 0,
            blog_found: 0,
            id: 0,
            composite_file_name: video.name, // CHECK THIS
            thumbnail_file_name: thumbnailName,
            date_received: null
        };


        let burstMediaRadixObject = {
            payload_token_identifier,
            media_radix: mediaRadix,
            version: 1,
            uid,
            image: null,
            thumbnail: null,
            image_name: videoName,
            thumbnail_name: thumbnailName,
            file_type: 1, // 1=video, 2=image
            file_size: video.size
        };

        const data = new FormData();
        data.append("json_data", JSON.stringify([burstMediaRadixObject]));
        data.append(video.name, video);


        futch(
            URL,
            {
                method: "POST",
                body: data,
                headers: {}
            },
            progressEvent => {

                const progress = progressEvent.loaded / progressEvent.total;
                progressCallback(progress);

            }
        )
            .then(res => {
                console.log(res);

                const resp = JSON.parse(res.response);

                if (resp.error) {
                    completionCallback(false, res.response);
                    // dispatch(MessageActions.setError(resp.msg));
                } else {
                    // dispatch(
                    //     MessageActions.setSuccess("Your video was uploaded successfully")
                    // );
                }

                completionCallback(true, res.response);
            })
            .catch(err => {
                console.warn("Error with video upload", err);
                completionCallback(false, "Error with video upload");
                // dispatch(
                //     MessageActions.setError("There was an error uploading your video")
                // );
            });
    };
}
