import React, { Component } from "react";
import Flexbox from "flexbox-react";
import { isIE } from "react-device-detect";
import "../../App.css";
import logo from "../assets/bennett_logo.png";
import logo_ie from "../assets/bennett_logo_ie.png";
import ButtonOpacity from "./components/ButtonOpacity";
import ButtonOpacitySVG from "./components/ButtonOpacitySVG";
import { MenuBlue } from "../styles/";
import * as Constants from "../../Constants";

import { Link } from "react-router-dom";
import { AMReturnBar } from "innercast-core-js";
// import {Button} from "semantic-ui-react";

class TopBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amReturnBarVisible: false,
        };
    }

    componentDidMount() {
        const amReturnBar = document.getElementById("am-return-bar");
        if (amReturnBar) {
            this.setState({ amReturnBarVisible: true });
        } else {
            this.setState({ amReturnBarVisible: false });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            const amReturnBar = document.getElementById("am-return-bar");
            if (amReturnBar) {
                this.setState({ amReturnBarVisible: true });
            } else {
                this.setState({ amReturnBarVisible: false });
            }
        }
    }

    render() {
        const menu = (
            <ButtonOpacity onClick={() => this.props.didAskForMenu(true)} iconName="menu_button" title="Show sidebar menu" />
        );

        return (
            <Flexbox
                flexDirection="column"
                style={{
                    position: "fixed",
                    background: "yellow",
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 2,
                }}>
                <Flexbox
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                        background: MenuBlue,
                        padding: 10,
                        height: Constants.TOP_BAR_HEIGHT,
                    }}>
                    <div>{this.props.menuIsVisible ? "" : menu}</div>

                    <Link to="/">
                        <img data-tut="reactour__topbar" src={isIE ? logo_ie : logo} alt="AgMarket" height={40} />
                    </Link>

                    <Flexbox flexDirection="row">
                        {isIE ? null : (
                            <ButtonOpacitySVG onClick={this.props.showMainTour} iconName="help" title="Show Standard Tutorial" />
                        )}
                    </Flexbox>
                </Flexbox>
                <AMReturnBar user={this.props.user} />
            </Flexbox>
        );
    }
}

export default TopBar;
