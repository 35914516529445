import React, {Component, Suspense} from 'react';
import 'signup/css/App.css';
import {Route, Switch} from 'react-router-dom'
import {Elements, StripeProvider} from 'react-stripe-elements';
// import {DEBUG} from '../../Constants'
import LazyLoadingComponentPlaceholder from '../../viewerapp/components/LazyLoadingComponentPlaceholder';
import MyErrorBoundary from '../../viewerapp/components/MyErrorBoundary'

//Public Base
const PublicLandingPageScreen = React.lazy(() => import("../../signup/containers/PublicLandingPageScreen"));
const LoginScreen = React.lazy(() => import("../../signup/containers/LoginScreen"));
// const CoBrandLandingPageScreen = React.lazy(() => import("../../signup/containers/CoBrandLandingPageScreen"));
// const CreateAccountScreen = React.lazy(() => import("../../signup/containers/CreateAccountScreen"));
// const BillingScreen = React.lazy(() => import("../../signup/containers/BillingScreen"));
// const PaymentSuccessScreen = React.lazy(() => import("../../signup/containers/PaymentSuccessScreen"));
// const BillingRedirectScreen = React.lazy(() => import("../../signup/containers/BillingRedirectScreen"));
// const PromoCodeSuccessScreen = React.lazy(() => import("../../signup/containers/PromoCodeSuccessScreen"));
// const TwoWeekTrialSuccessScreen = React.lazy(() => import("../../signup/containers/TwoWeekTrialSuccessScreen"));
// const EmailVerificationSuccessScreen = React.lazy(() => import("../../signup/containers/EmailVerificationSuccessScreen"));
// const EmailVerificationErrorScreen = React.lazy(() => import("../../signup/containers/EmailVerificationErrorScreen"));
const ResetPasswordScreen = React.lazy(() => import('../../viewerapp/containers/ResetPasswordScreen'));

class PublicBase extends Component {
    render() {
        // const renderBillingScreen = (props) => {
        //     let stripeKey = 'pk_test_EGzVQpkrgziSInftllnG5AJw'
        //     if (!DEBUG()) {
        //         stripeKey = 'pk_live_b2BGuyBBPul9R9RUDuUGbqLj'
        //     }
        //
        //
        //     // console.log(stripeKey)
        //     const allProps = Object.assign(props, this.props)
        //
        //     return (
        //         <MyErrorBoundary>
        //             <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
        //                 <StripeProvider apiKey={stripeKey}>
        //                     <Elements>
        //                         <BillingScreen {...allProps}/>
        //                     </Elements>
        //                 </StripeProvider>
        //             </Suspense>
        //         </MyErrorBoundary>
        //     )
        // };

        const renderPublicLandingPageScreen = (props) => {
            const allProps = Object.assign(props, this.props);

            // this is the catch-all route, so if the user does something stupid like put in /asdglfkajsdglkj
            // then the app will redirect them to something more sensible like /app
            if (props.location.pathname !== "/") {
                props.history.replace("/");
            }
            
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
                        <PublicLandingPageScreen {...allProps}/>
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        // const renderPromoCodeSuccessScreen = (props) => {
        //     const allProps = Object.assign(props, this.props);
        //     return (
        //         <MyErrorBoundary>
        //             <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
        //                 <PromoCodeSuccessScreen {...allProps}/>
        //             </Suspense>
        //         </MyErrorBoundary>
        //     );
        // };

        // const renderPaymentSuccessScreen = (props) => {
        //     const allProps = Object.assign(props, this.props);
        //     return (
        //         <MyErrorBoundary>
        //             <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
        //                 <PaymentSuccessScreen {...allProps}/>
        //             </Suspense>
        //         </MyErrorBoundary>
        //     );
        // };

        // const renderTwoWeekTrialSuccessScreen = (props) => {
        //     const allProps = Object.assign(props, this.props);
        //     return (
        //         <MyErrorBoundary>
        //             <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
        //                 <TwoWeekTrialSuccessScreen {...allProps}/>
        //             </Suspense>
        //         </MyErrorBoundary>
        //     );
        // };

        // const renderEmailVerificationSuccessScreen = (props) => {
        //     const allProps = Object.assign(props, this.props);
        //     return (
        //         <MyErrorBoundary>
        //             <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
        //                 <EmailVerificationSuccessScreen {...allProps}/>
        //             </Suspense>
        //         </MyErrorBoundary>
        //     );
        // };

        // const renderEmailVerificationErrorScreen = (props) => {
        //     const allProps = Object.assign(props, this.props);
        //     return (
        //         <MyErrorBoundary>
        //             <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
        //                 <EmailVerificationErrorScreen {...allProps}/>
        //             </Suspense>
        //         </MyErrorBoundary>
        //     );
        // };

        // const renderBillingRedirectScreen = (props) => {
        //     const allProps = Object.assign(props, this.props);
        //     return (
        //         <MyErrorBoundary>
        //             <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
        //                 <BillingRedirectScreen {...allProps}/>
        //             </Suspense>
        //         </MyErrorBoundary>
        //     );
        // };

        // const renderCoBrandLandingPageScreen = (props) => {
        //     const allProps = Object.assign(props, this.props);
        //     return (
        //         <MyErrorBoundary>
        //             <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
        //                 <CoBrandLandingPageScreen {...allProps}/>
        //             </Suspense>
        //         </MyErrorBoundary>
        //     );
        // };

        // const renderCreateAccountScreen = (props) => {
        //     const allProps = Object.assign(props, this.props);
        //     return (
        //         <MyErrorBoundary>
        //             <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
        //                 <CreateAccountScreen {...allProps}/>
        //             </Suspense>
        //         </MyErrorBoundary>
        //     );
        // };

        const renderLoginScreen = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
                        <LoginScreen {...allProps}/>
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        const resetPasswordScreen = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
                        <ResetPasswordScreen {...allProps}/>
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        if (window.location.pathname === '/' || window.location.pathname === '') {
            return (
                <StripeProvider apiKey="pk_test_EGzVQpkrgziSInftllnG5AJw">
                    <Elements>
                        <Switch>
                            <Route exact path="/" render={renderPublicLandingPageScreen}/>
                        </Switch>
                    </Elements>
                </StripeProvider>
            )
        } else {
            return (
                <div className="backgroundImage">
                    <StripeProvider apiKey="pk_test_EGzVQpkrgziSInftllnG5AJw">
                        <Elements>
                            <Switch>
                                <Route exact path="/login" render={renderLoginScreen}/>
                                {/*<Route exact path="/create_account" render={renderCreateAccountScreen}/>*/}
                                {/*<Route exact path="/promo_code_success" render={renderPromoCodeSuccessScreen}/>*/}
                                {/*<Route exact path="/payment_success" render={renderPaymentSuccessScreen}/>*/}
                                {/*<Route exact path="/two_week_trial_success" render={renderTwoWeekTrialSuccessScreen}/>*/}
                                {/*<Route exact path="/email_verification_success"*/}
                                {/*       render={renderEmailVerificationSuccessScreen}/>*/}
                                {/*<Route exact path="/email_verification_error"*/}
                                {/*       render={renderEmailVerificationErrorScreen}/>*/}
                                {/*<Route exact path='/billing_redirect' render={renderBillingRedirectScreen}/>*/}
                                {/*<Route exact path="/billing" render={renderBillingScreen} {...this.props}/>*/}
                                {/*<Route path="/promo/:cobrand" render={renderCoBrandLandingPageScreen}/>*/}
                                <Route exact path="/resetpassword" render={resetPasswordScreen}/>
                                <Route render={renderPublicLandingPageScreen}/>
                            </Switch>
                        </Elements>
                    </StripeProvider>
                </div>
            );
        }
    }
}

export default PublicBase
