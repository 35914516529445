import createReducer from '../lib/createReducer'
import * as types from '../actions/types'

export const promocodes = createReducer({
    list: null,
}, {

    // manage login
    [types.SET_PROMO_CODES](state, action) {
        let newState = Object.assign({}, state);
        console.log(action.info)
        if (action.info !== null) {
            newState.list = action.promo_codes;
        } else {
            newState.list = [];
        }
        return newState;
    },



});
