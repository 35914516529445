export const RESET_TO_DEFAULTS = "RESET_TO_DEFAULTS";

// Ticker
export const SET_CORN_AND_SOY_FUTURES = "SET_CORN_AND_SOY_FUTURES";

// Grain Cash bids
export const SET_ZIPCODE = "SET_ZIPCODE";
export const SET_SEARCH_RADIUS_MILES = "SET_SEARCH_RADIUS_MILES";
export const SET_CASH_BIDS = "SET_CASH_BIDS";
export const SET_PINNED_BIDS = "SET_PINNED_BIDS";
export const SET_FILTER_FOR_COMMODITY = "SET_FILTER_FOR_COMMODITY";
export const SET_FILTER_FOR_DELIVERY_MONTH = "SET_FILTER_FOR_DELIVERY_MONTH";
export const RESET_REDUX_CASH_BID_SEARCH = "RESET_REDUX_CASH_BID_SEARCH";

// history
export const SET_HISTORY_FOR_SYMBOL = "SET_HISTORY_FOR_SYMBOL";
export const UPDATE_LAST_HISTORY_LOAD_TIMESTAMP = "UPDATE_LAST_HISTORY_LOAD_TIMESTAMP";

export const SET_CROP_YEAR_DATA = "SET_CROP_YEAR_DATA";

// bennett app (stuff that will hit Linx Servers)
export const SET_OUTLOOK = "SET_OUTLOOK";
export const SET_MY_FARM_STATS = "SET_MY_FARM_STATS";

export const SET_FARM_REPORT_YEARS = "SET_FARM_REPORT_YEARS";
export const SET_SALES_RECORDS = "SET_SALES_RECORDS";

// storydynamics app (generic stuff that hits Linx Servers)  app agnostic
export const SET_ARTICLES = "SET_ARTICLES";
export const SET_ENDPOINT_URLS = "SET_ENDPOINT_URLS";

// Widget State
export const TOGGLE_MY_FARM_VISIBILITY = "TOGGLE_MY_FARM_VISIBILITY";
export const TOGGLE_GRAIN_BID_MAX_DISPLAY = "TOGGLE_GRAIN_BID_MAX_DISPLAY";
export const DETECTED_POSSIBLE_ORIENTATION_CHANGE = "DETECTED_POSSIBLE_ORIENTATION_CHANGE";
export const TOGGLE_GRAIN_BID_FILTERING_VIEW = "TOGGLE_GRAIN_BID_FILTERING_VIEW";
export const TOGGLE_FUTURE_BOARDS_VISIBILITY = "TOGGLE_FUTURE_BOARDS_VISIBILITY";
export const SET_FUTURE_BOARD_COMMODITY_TYPE = "SET_FUTURE_BOARD_COMMODITY_TYPE";
export const TOGGLE_OUTLOOK_VISIBILITY = "TOGGLE_OUTLOOK_VISIBILITY";
export const TOGGLE_CASH_BIDS_VISIBILITY = "TOGGLE_CASH_BIDS_VISIBILITY";
export const REMOVE_REDUX_PINNED_BID = "REMOVE_REDUX_PINNED_BID";

export const SET_FILTERS_FOR_COMMODITIES___WEB = "SET_FILTERS_FOR_COMMODITIES___WEB";
export const TOGGLE_SIDEBAR_VISIBLE___WEB = "TOGGLE_SIDEBAR_VISIBLE___WEB";
export const SET_MAIN_TOUR_AS_WATCHED___WEB = "SET_MAIN_TOUR_AS_WATCHED___WEB";

export const SET_MY_SELECTED_FARM_YEAR = "SET_MY_SELECTED_FARM_YEAR";
export const SET_ACTIVE_MY_FARM_COMMODITY = "SET_ACTIVE_MY_FARM_COMMODITY";

export const SET_CO_BRANDED_INFO = "SET_CO_BRANDED_INFO";
export const SHOWN_CO_BRAND = "SHOWN_CO_BRAND";

export const TOGGLE_CHANGELOG_VISIBILITY = "TOGGLE_CHANGELOG_VISIBILITY";

// User action types
export const LOGOUT = "LOGOUT";
export const SET_IS_LOGGED_IN_STATUS = "SET_IS_LOGGED_IN_STATUS";
export const SET_LOGIN = "SET_LOGIN";

// QUEUE
export const ENQUEUE_JOB = "ENQUEUE_JOB";
export const DEQUEUE_JOB = "DEQUEUE_JOB";
export const CLEAR_QUEUE = "CLEAR_QUEUE";

// ERROR
export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

// SUCCESS
export const SET_SUCCESS = "SET_SUCCESS";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

export const SET_EDITOR_TOOLBAR_DISPLAY = "SET_EDITOR_TOOLBAR_DISPLAY";

// CRM
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_ASSOCIATION_LIST = "SET_ASSOCIATION_LIST";
export const SET_USER_DETAILS_INFO = "SET_USER_DETAILS_INFO";
export const SET_ASSOCIATION_DETAILS_INFO = "SET_ASSOCIATION_DETAILS_INFO";
export const CHANGE_MY_DETAILS = "CHANGE_MY_DETAILS";
export const CHANGE_USER_DETAILS = "CHANGE_USER_DETAILS";
export const CHANGE_ASSOCIATION_DETAILS = "CHANGE_ASSOCIATION_DETAILS";
export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";
export const CLEAR_ASSOCIATION_DETAILS = "CLEAR_ASSOCIATION_DETAILS";
export const RESET_CRM_STATE = "RESET_CRM_STATE";
export const SET_USER_ACTIVITY_DETAILS = "SET_USER_ACTIVITY_DETAILS";
export const SET_USER_DEVICE_DETAILS_INFO = "SET_USER_DEVICE_DETAILS_INFO";
export const SET_DROPDOWN_OPTIONS = "SET_DROPDOWN_OPTIONS";
export const UPDATE_SELF = "UPDATE_SELF";
export const SET_AM_COOKIE = "SET_AM_COOKIE";
export const SET_PM_COOKIE = "SET_PM_COOKIE";

//
export const SET_NEW_ACCOUNT_INFO = "SET_NEW_ACCOUNT_INFO";
export const UPDATE_ACCOUNT_INFO = "UPDATE_ACCOUNT_INFO";

//PROMO CODES
export const SET_PROMO_CODES = "SET_PROMO_CODES";
