import React, { Component, Suspense } from "react";
import "./App.css";
import "viewerapp/styles/messages.css";

import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { ActionCreators } from "viewerapp/actions";
import { bindActionCreators } from "redux";

import SuccessToast from "viewerapp/components/SuccessToast";
import ErrorToast from "viewerapp/components/ErrorToast";
import { AuthGroup } from "./Constants";
import "styles/LoginView.css";
import Viewerapp from "routes/viewerapp/Viewerapp";
import Signup from "routes/signup/Signup";
import Dealer from "routes/dealer/Dealer";

import MyErrorBoundary from "./viewerapp/components/MyErrorBoundary";
import LazyLoadingComponentPlaceholder from "./viewerapp/components/LazyLoadingComponentPlaceholder";

const CRMManageScreen = React.lazy(() =>
    import(/* webpackChunkName: "CRMManageScreen" */ "viewerapp/containers/CRMManageScreen"),
);
const RouterRenderer = withRouter((props) => props.render());

class App extends Component {
    constructor(props) {
        super(props);
        this.props.validate();
    }

    componentDidMount() {
        this.props.checkLoginStatus();
    }

    renderDealer = () => {
        return <Dealer {...this.props} />;
    };

    renderSignup = () => {
        return <Signup {...this.props} />;
    };

    renderViewerapp = () => {
        return <Viewerapp {...this.props} />;
    };

    render() {
        return (
            <div style={{ flex: 1, height: "100vh" }}>
                <Router>
                    <RouterRenderer render={() => this.renderContent()} />
                </Router>
            </div>
        );
    }

    renderContent = () => {
        const authenticated = this.props.user.isLoggedIn && this.props.user.uid;
        const groupID = this.props.user.groupID;

        const renderMobileCRM = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <CRMManageScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        if (!authenticated) {
            return (
                <React.Fragment>
                    <Switch>
                        <Route exact path="/crm/mobile-user-details/:asker/:target/:token" render={renderMobileCRM} />
                        <Route render={this.renderSignup} {...this.props} />
                    </Switch>
                    <SuccessToast message={this.props.messages.success_message} />
                    <ErrorToast message={this.props.messages.error_message} />
                </React.Fragment>
            );
        }

        if (groupID === AuthGroup.Dealer) {
            return (
                <React.Fragment>
                    <Route render={this.renderDealer} />}
                    <SuccessToast message={this.props.messages.success_message} />
                    <ErrorToast message={this.props.messages.error_message} />
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Route render={this.renderViewerapp} {...this.props} />

                <SuccessToast message={this.props.messages.success_message} />
                <ErrorToast message={this.props.messages.error_message} />
            </React.Fragment>
        );
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        barcharts: state.barcharts,
        cashbids: state.barcharts.cashbids,
        outlook: state.bennettapp.outlook,
        myFarmStats: state.bennettapp.myFarmStats,
        widgetstate: state.widgetstate,
        articles: state.storydynamics.articles,
        user: state.user,
        messages: state.messages,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
