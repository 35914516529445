import React, { Component } from "react";
import "../../App.css";
import Flexbox from "flexbox-react";
import { isIE } from "react-device-detect";
import SidebarItem from "./sidebar_subviews/SidebarItem";
import SidebarGroup from "./sidebar_subviews/SidebarGroup";

import { AuthGroup } from "../../Constants";

import { MenuBlue } from "../styles";

import ButtonOpacitySVG from "./components/ButtonOpacitySVG";
import { Button, Icon } from "semantic-ui-react";

import packageJSON from "../../../package.json";

import sidebar_promo_code from "../assets/sidebar_promo_code.svg";

import Chirper from "../assets/bird-silhouette.svg";

class SidebarContent extends Component {
    // For IE
    state = {
        winHeight: window.innerHeight,
    };

    updateWinHeight = () => {
        this.setState({ winHeight: window.innerHeight });
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateWinHeight);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWinHeight);
    }

    handleLogoutRequest = () => {
        this.props.logout();
        window.location = "/";
    };

    render() {
        let username = this.props.user.username;

        let sidebarToggleText = "Dock menu to side of window so it will stay visible";
        let sidebarToggleImage = "open_pane";
        if (this.props.widgetstate.sidebarVisible) {
            sidebarToggleText = "Hide Sidebar";
            sidebarToggleImage = "close_pane";
        }

        const isAM = this.props.user.groupID === AuthGroup.AccountManager;
        const isPM = this.props.user.groupID === AuthGroup.ProjectManager;
        const isAuthor = this.props.user.groupID === AuthGroup.Author;

        const compose =
            isAM || isPM || isAuthor ? (
                <SidebarItem
                    dataTut="reactour__compose"
                    link="/app/compose"
                    title="Compose"
                    iconName="sidebar_compose"
                    onClick={this.props.onNavClick}
                />
            ) : null;

        const reports = (
            <SidebarGroup
                dataTut="reactour__myfarm"
                link="/app/myfarm"
                title="My Farm"
                iconName="sidebar_myfarm"
                onClick={this.props.onNavClick}>
                <SidebarItem
                    dataTut="reactour__myfarm-reports"
                    submenuLevel={1}
                    link="/app/myfarm/reports"
                    title="Reports"
                    iconName="sidebar_spreadsheet"
                    onClick={this.props.onNavClick}
                />
                <SidebarItem
                    dataTut="reactour__myfarm-hedge-reports"
                    submenuLevel={1}
                    link="/app/myfarm/ProfitOptimizer"
                    title="Profit Optimizer"
                    iconName="sidebar_spreadsheet"
                    onClick={this.props.onNavClick}
                />
            </SidebarGroup>
        );

        const crm =
            isAM || isPM || isAuthor ? (
                <SidebarItem
                    dataTut="reactour__crm"
                    link="/app/crm"
                    title="Users"
                    iconName="sidebar_manage"
                    onClick={this.props.onNavClick}
                />
            ) : (
                <SidebarItem
                    dataTut="reactour__crm"
                    link={`/app/crm/myaccount/${this.props.user.uid}/${this.props.user.uid}/${this.props.user.token}`}
                    title="Account Settings"
                    iconName="sidebar_manage"
                    onClick={this.props.onNavClick}
                />
            );
        const promo_code =
            isAM || isPM ? (
                <SidebarItem
                    dataTut="reactour__promo_code"
                    link="/app/promo_code"
                    title="Promo Codes"
                    svgIcon={sidebar_promo_code}
                    onClick={this.props.onNavClick}
                />
            ) : null;

        return (
            <Flexbox
                flex="1"
                flexDirection="column"
                alignItems="flex-start"
                style={{ minWidth: 170, background: MenuBlue, color: "white", minHeight: "100%" }}>
                <div
                    style={{ color: "orange", padding: 15, paddingLeft: 28, wordBreak: "break-all" }}
                    data-tut="reactour__sidebar-user">
                    <div style={{ paddingBottom: 10 }}>
                        {username}
                        <br />
                    </div>
                    <Button
                        size="tiny"
                        inverted
                        icon
                        labelPosition="left"
                        onClick={this.handleLogoutRequest}
                        disabled={this.props.user.am_auth_token !== null || this.props.user.pm_auth_token !== null}>
                        <Icon name="sign out" />
                        Logout
                    </Button>
                </div>
                <div style={{ width: "100%" }}>
                    <hr style={{ border: "1px solid #20384D" }} />
                </div>

                <SidebarItem
                    dataTut="reactour__home"
                    link="/app"
                    title="Home"
                    iconName="sidebar_home"
                    onClick={this.props.onNavClick}
                />
                <SidebarItem
                    dataTut="reactour__cashbids"
                    link="/app/cashbids"
                    title="Cash Bids"
                    iconName="sidebar_cashbids"
                    onClick={this.props.onNavClick}
                />
                {reports}
                <SidebarItem
                    dataTut="reactour__futures"
                    link="/app/futures"
                    title="Futures"
                    iconName="sidebar_futures"
                    onClick={this.props.onNavClick}
                />
                <SidebarItem
                    dataTut="reactour__chirper"
                    link="/app/chirper"
                    title="Chirper"
                    svgIcon={Chirper}
                    onClick={this.props.onNavClick}
                />
                <SidebarItem
                    dataTut="reactour__news"
                    link="/app/news"
                    title="News"
                    iconName="sidebar_news"
                    onClick={this.props.onNavClick}
                />
                {compose}

                {crm}

                {promo_code}

                <SidebarItem
                    link="/whatsnew"
                    title="What's New"
                    svgIcon={require("../assets/confetti.svg")}
                    onClick={this._showChangeLog}
                />

                <Flexbox
                    flexDirection="column"
                    alignSelf="center"
                    justifyContent="flex-end"
                    flexGrow={100}
                    style={isIE ? { marginTop: this.state.winHeight - 425 } : { marginBottom: 10 }}>
                    <ButtonOpacitySVG
                        dataTut="reactour__sidebar-anchor"
                        iconName={sidebarToggleImage}
                        alt={sidebarToggleText}
                        title={sidebarToggleText}
                        onClick={this.props.toggleSidebarDocked}
                    />
                    <span
                        title="View What's New"
                        style={{ fontSize: 10, marginTop: 20, cursor: "pointer" }}
                        onClick={this._showChangeLog}>
                        {packageJSON.version} ({process.env.REACT_APP_CURRENT_GIT_SHA})
                    </span>
                </Flexbox>
            </Flexbox>
        );
    }

    _showChangeLog = () => {
        this.props.toggleChangelogVisibility();
    };
}

export default SidebarContent;
