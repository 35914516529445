
/*
  Colors
*/

export const BackgroundBlue = "#20384D";
export const MenuBlue       = "#2F475D";
export const SectionBlue    = "#3B5A74";
export const AltRowBlue     = "#27425C";

export const BennettOrange  = "#E48D61";
export const BennettGreen   = "#AFBC82";


export const Row = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 5,
  paddingRight: 5,
}

export const AltRow = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 5,
  paddingRight: 5,
  backgroundColor: AltRowBlue,
};


export const tableSeparator = {
    backgroundColor: SectionBlue,
    height: 1,
}
