import {combineReducers} from 'redux';

import * as barchartReducers from './barchart';
import * as bennettappReducers from './bennettapp';
import * as widgetstateReducers from './widgetstate';
import * as storydynamicReducers from './storydynamics';
import * as userReducers from './user';
import * as queueReducers from './queue';
import * as messagesReducers from './messages'
import * as dealerWidgetstateReducers from 'dealer/reducers/widgetstate';
import * as customerReducers from 'dealer/reducers/customer';
import * as dealerDetailsReducers from 'dealer/reducers/dealerdetails';
import * as licensesReducers from 'dealer/reducers/licenses';
import * as registerReducers from 'signup/reducers/register'
import * as promoCodeReducers from './promocodes'


export default combineReducers(Object.assign(
    barchartReducers,
    bennettappReducers,
    widgetstateReducers,
    storydynamicReducers,
    userReducers,
    queueReducers,
    messagesReducers,
    dealerWidgetstateReducers,
    customerReducers,
    dealerDetailsReducers,
    licensesReducers,
    registerReducers,
    promoCodeReducers

));
