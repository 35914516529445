import React, { Component } from 'react';

class ButtonOpacity extends Component {

  constructor(props) {
    super(props);

    this.state = { opacity: 1.0 };
  }

  _onMouseEnter = () => {
    this.setState((prevState) => ({ opacity : prevState.opacity - 0.2}));
  }

  _onMouseLeave = () => {
    this.setState((prevState) => ({ opacity : prevState.opacity + 0.2}));
  }

  _onMouseDown = () => {
    this.setState((prevState) => ({ opacity : prevState.opacity - 0.2}));
  }

  _onMouseUp = () => {
    this.setState((prevState) => ({ opacity : prevState.opacity + 0.2}));
  }

  render() {

    const icon1x = require('../assets/'+this.props.iconName+'.png');
    const icon2x = require('../assets/'+this.props.iconName+'@2x.png');
    const icon3x = require('../assets/'+this.props.iconName+'@3x.png');



    const mergedStyle = Object.assign({opacity: this.state.opacity}, this.props.style);

    return (
      <img
        onClick={this.props.onClick}
        onMouseEnter={this._onMouseEnter}
        onMouseLeave={this._onMouseLeave}
        onMouseDown={this._onMouseDown}
        onMouseUp={this._onMouseUp}
        data-tut={this.props.dataTut}
        style={mergedStyle}
        alt={this.props.alt}
        title={this.props.title}
        src={icon1x}
        srcSet={`
          ${icon2x} 2x,
          ${icon3x} 3x
        `}
      />
    );
  }
}

export default ButtonOpacity;
