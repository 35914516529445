import Config from 'Config';

export const HOST = () => (Config.HOST);
export const DEBUG = () => (Config.DEBUG)
export const MEDIA_HOST = () => HOST() + "/media";

export const PrimaryColor = "#75AA3B"; // GSULive Green
export const LightPrimaryColor = "#82b24e"; // Light GSULive Green
export const LighterPrimaryColor = "#90bb62";
export const LightestPrimaryColor = "#9ec375";
export const DarkPrimaryColor = "#699935";
export const DarkerPrimaryColor = "#5d882f";
export const DarkestPrimaryColor = "#517629";

export const TertiaryColor = "#40434E"; // Gray color
export const LightTertiaryColor = "#53555F";
export const LighterTertiaryColor = "#666871";
export const LightestTertiaryColor = "#797b83";
export const DarkTertiaryColor = "#393c46";
export const DarkerTertiaryColor = "#33353e";
export const DarkestTertiaryColor = "#2c2e36";

export const BACKGROUND_BLUE = "rgb(32,56,77,1)"
export const BackgroundBlue = "rgb(32,56,77,1)"
export const SectionBlue    = "#3B5A74";

export const CORN_TYPE = "corn";
export const SOYBEAN_TYPE = "soybean";

export const TOP_BAR_HEIGHT = 56;
export const VIEWING_AS_HEIGHT = 40;

export const AuthGroup = {
    AccountManager: 1,
    ProjectManager: 2,
    Author: 3,
    Viewer: 4,
    Dealer: 5,
};

// queue job types
export const JOB__PUBLISH = "JOB__PUBLISH";
export const JOB__PUBLISH_VIDEO = "JOB__PUBLISH_VIDEO";
export const JOB__IMAGE_UPLOAD = "JOB__IMAGE_UPLOAD";
export const JOB__VIDEO_UPLOAD = "JOB__VIDEO_UPLOAD";

// queue job states
export const JOB_STATE_PENDING = "JOB_STATE_PENDING";
export const JOB_STATE_STOPPED = "JOB_STATE_STOPPED";
export const JOB_STATE_ACTIVE = "JOB_STATE_ACTIVE";
export const JOB_STATE_COMPLETE = "JOB_STATE_COMPLETE";

export const ICON_AND_TEXT = 'ICON_AND_TEXT';
export const ICON_ONLY = 'ICON_ONLY';
export const TOOLBAR_Z_INDEX = 5;

export const ComposeCharCountsTitle = {
    SuggestedAbsoluteMinimum: 5,
    SuggestedMinimum: 10,
    SuggestedMaximum: 86,
    SuggestedAbsoluteMaximum: 100
};
export const ComposeCharCountsSummary = {
    SuggestedAbsoluteMinimum: 25,
    SuggestedMinimum: 100,
    SuggestedMaximum: 250,
    SuggestedAbsoluteMaximum: 300
};
export const ComposeCharCountsTextBlock = {
    SuggestedAbsoluteMinimum: 10,
    SuggestedMinimum: 50,
    SuggestedMaximum: 400,
    SuggestedAbsoluteMaximum: 750

};

export const TitleFontSize = {
    LargeFont: '40px',
    SmallFont: '25px'
}

export const BurstTextFontSize = {
    LargeFont: '30px',
    SmallFont: '20px'
}

export const EditableFieldColor = "#FAFAFA";

export const ArticleTypeBurst = "ArticleType.Burst";
export const ArticleTypeVideo = "ArticleType.Video";

export const PHOTOS = 1;         // 0001
export const VIDEOS = 1 << 1;    // 0010

export const VERSION = 'v2/';


export const iOSAppStoreURL = "https://apps.apple.com/us/app/agmarket-net/id1334806282?ls=1";
export const playStoreURL = "https://play.google.com/store/apps/details?id=co.storydynamics.a.bennettapp.viewer";
