import React, {Component} from 'react';
import 'dealer/styles/App.css';
import Flexbox from 'flexbox-react';
import SidebarItem from 'dealer/views/Sidebar/SidebarItem';


import {TertiaryColor} from 'Constants';


class SidebarContent extends Component {


    logout = () => {
        this.props.logout()
        // window.location = '/'
    }


    render() {

        // let sidebarToggleText = "Dock menu to side of window so it will stay visible";
        // let sidebarToggleImage = "open_pane";
        // if (this.props.widgetstate.sidebarVisible) {
        //   sidebarToggleText = "Hide Sidebar";
        //   sidebarToggleImage = "close_pane";
        // }

        /*
        <SidebarItem dataTut="reactour__cashbids" link="/cashbids" title="Cash Bids" iconName="sidebar_cashbids" onClick={this.props.onNavClick}/>
        <SidebarGroup dataTut="reactour__myfarm" link="/myfarm" title="My Farm" iconName="sidebar_myfarm" onClick={this.props.onNavClick}>
          <SidebarItem dataTut="reactour__myfarm-reports" submenuLevel={1} link="/myfarm/reports" title="Reports" iconName="sidebar_spreadsheet"  onClick={this.props.onNavClick} />
        </SidebarGroup>
        <SidebarItem dataTut="reactour__futures" link="/futures" title="Futures" iconName="sidebar_futures" onClick={this.props.onNavClick}/>
        <SidebarItem dataTut="reactour__news" link="/news" title="News" iconName="sidebar_news" onClick={this.props.onNavClick}/>

        <Flexbox flexDirection="column" alignSelf="center" justifyContent="flex-end" flexGrow={100} style={{marginBottom: 10}}>
          <ButtonOpacitySVG
            dataTut="reactour__sidebar-anchor"
            iconName={sidebarToggleImage}
            alt={sidebarToggleText}
            title={sidebarToggleText}
            onClick={this.props.toggleSidebarDocked}
          />
        </Flexbox>
        */

        return (
            <Flexbox flex="1" flexDirection="column" alignItems="flex-start"
                     style={{minWidth: 170, background: TertiaryColor, color: 'white', minHeight: "100%"}}>
                <SidebarItem dataTut="reactour__customers" link="/app/dealer/customers" title="Customers"
                             svgIconName="customers" onClick={this.props.onNavClick}/>
                <SidebarItem dataTut="reactour__licenses" link="/app/dealer/licenses" title="Licenses"
                             svgIconName="gift_card" onClick={this.props.onNavClick}/>
                <SidebarItem dataTut="reactour__editlandingpage" link="/app/dealer/settings" title="Edit Landing Page"
                             svgIconName="design" onClick={this.props.onNavClick}/>
                <SidebarItem dataTut="reactour__help" link="/app/dealer/help" title="Help" svgIconName="help"
                             onClick={this.props.onNavClick}/>
                <SidebarItem dataTut="reactour__logout" link="/" title="Logout" svgIconName="logout"
                             onClick={this.logout}/>
            </Flexbox>
        );
    }
}

export default SidebarContent;
