import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { isIE } from "react-device-detect";
import { Sidebar } from "semantic-ui-react";
import { connect } from "react-redux";
import { ActionCreators } from "signup/actions";
import { bindActionCreators } from "redux";
import Joyride from "react-joyride";
import { EVENTS } from "react-joyride/es/constants";
import { BasicTourSteps } from "dealer/tour";
import LazyLoadingComponentPlaceholder from "viewerapp/components/LazyLoadingComponentPlaceholder";
import MyErrorBoundary from "viewerapp/components/MyErrorBoundary";
import SidebarContent from "dealer/views/Sidebar/SidebarContent";
import "dealer/styles/App.css";
//
//DealerVPP
import TopBar from "dealer/components/TopBar";
import { AMReturnBar } from "innercast-core-js";
const CustomersScreen = React.lazy(() => import("dealer/containers/CustomersScreen"));
const LicensesScreen = React.lazy(() => import("dealer/containers/LicensesScreen"));
const DealerDetailsScreen = React.lazy(() => import("dealer/containers/DealerDetailsScreen"));
const HelpScreen = React.lazy(() => import("dealer/containers/HelpScreen"));

const mql = window.matchMedia(`(min-width: 800px)`);

class DealerVPP extends Component {
    constructor(props) {
        super(props);

        //  console.log(props);

        const width = window.innerWidth;
        //console.log(width);
        this.state = {
            tourCount: 0,
            isTourOpen: false,
            sidebarDocked: width > 800,
            sidebarOpen: width > 800 && this.props.widgetstate.sidebarVisible,
            sidebarVisibleOverride: this.props.widgetstate.sidebarVisible,
        };
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);

        //    setInterval(()=> this.setState({isTourOpen: !this.state.isTourOpen}), 3000);
    }

    componentWillMount() {
        mql.addListener(this.mediaQueryChanged);
    }

    componentWillUnmount() {
        if (this.state.mql) {
            this.state.mql.removeListener(this.mediaQueryChanged);
        }
    }

    componentDidMount() {
        if (!this.props.widgetstate.mainTourHasBeenWatched) {
            // main tour has not been viewed... show it.
            this._showMainTour();
        }
    }

    _showMainTour = () => {
        if (!this.state.sidebarOpen || !this.state.sidebarDocked) {
            setTimeout(
                () =>
                    this.setState({
                        tourCount: this.state.tourCount + 1,
                        isTourOpen: true,
                    }),
                250,
            );
        } else {
            this.setState({
                sidebarOpen: true,
                tourCount: this.state.tourCount + 1,
                isTourOpen: true,
            });
        }

        //console.log(this.state.tourCount);
        this.props.setMainTourAsWatched();
    };

    callback = (tour) => {
        const { index, type } = tour;

        if (type === EVENTS.STEP_BEFORE && index === 1) {
            // open the sidebar if necessary before continuing
            if (!this.state.sidebarOpen || !this.state.sidebarDocked) {
                this.setState({
                    isTourOpen: false,
                    sidebarOpen: true,
                });
                setTimeout(
                    () =>
                        this.setState({
                            isTourOpen: true,
                        }),
                    250,
                );

                //    console.log("opening sidebar...");
            }
        } else if ([EVENTS.TOUR_END, EVENTS.CLOSE, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Sunce this is a controlled tour you'll need to update the state to advance the tour
            //  console.log("closing tour");
            this.setState({ isTourOpen: false });
        }
    };

    onSetSidebarOpen(open) {
        //  console.log("onSetSidebarOpen");

        if (!this.state.isTourOpen) {
            this.setState({ sidebarOpen: open });
        }
    }

    mediaQueryChanged() {
        console.log(mql);
        if (this.props.widgetstate.sidebarVisible) {
            this.setState({ sidebarDocked: mql.matches, sidebarOpen: mql.matches });
        }
    }

    _handleNavClick = () => {
        if (!this.state.isTourOpen) {
            this.setState({ sidebarOpen: this.state.sidebarDocked });
        }
    };

    _toggleSidebarDocked = () => {
        //    console.log("handle sidebar hiding");
        //  this.setState({sidebarOpen: this.state.sidebarDocked});

        const newVal = !this.props.widgetstate.sidebarVisible;

        this.props.toggleSidebarVisible(newVal);
        if (newVal) {
            this.setState({ sidebarOpen: true, sidebarDocked: true });
        } else {
            this.setState({ sidebarOpen: false, sidebarDocked: false });
        }
    };

    renderAuthenticated = () => {
        const renderCustomers = (props) => {
            // this is the catch-all route, so if the user does something stupid like put in /asdglfkajsdglkj
            // then the app will redirect them to something more sensible like /app
            if (props.location.pathname !== "/app/dealer") {
                props.history.replace("/app/dealer");
            }

            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <CustomersScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };
        const renderLicenses = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <LicensesScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };
        const renderDealerDetailsScreen = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <DealerDetailsScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };
        const renderHelpScreen = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <HelpScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        let sidebarVisible = this.state.sidebarOpen;
        let animation = this.state.sidebarDocked ? "slide along" : "overlay";
        let contentLeftMargin = sidebarVisible && this.state.sidebarDocked ? 170 : 0;

        return (
            <div>
                <TopBar
                    menuIsVisible={this.state.sidebarDocked}
                    showMainTour={this._showMainTour}
                    didAskForMenu={this.onSetSidebarOpen}
                    {...this.props}
                />
                <Sidebar
                    data-tut="reactour__sidebar"
                    visible={sidebarVisible}
                    animation={animation}
                    className="sidebar-dealer"
                    onHide={() => {
                        if (!this.state.sidebarDocked) {
                            this.onSetSidebarOpen(false);
                        }
                    }}
                    width="thin">
                    <SidebarContent
                        onNavClick={this._handleNavClick}
                        toggleSidebarDocked={this._toggleSidebarDocked}
                        {...this.props}
                    />
                </Sidebar>

                <div style={{ background: "white", color: "black", marginLeft: contentLeftMargin, height: "100vh" }}>
                    <div style={{ position: "relative", top: 66, right: 0, bottom: 0, height: "calc(100% - 56px)" }}>
                        <AMReturnBar user={this.props.user} />
                        <Switch>
                            <Route exact path="/app/dealer/customers" render={renderCustomers} {...this.props} />
                            <Route exact path="/app/dealer/licenses" render={renderLicenses} {...this.props} />
                            <Route exact path="/app/dealer/settings" render={renderDealerDetailsScreen} {...this.props} />
                            <Route exact path="/app/dealer/help" render={renderHelpScreen} {...this.props} />
                            <Route path="/app/dealer" render={renderCustomers} {...this.props} />
                            <Route render={renderCustomers} {...this.props} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="root">
                {this.renderAuthenticated()}
                {isIE ? null : (
                    <Joyride
                        steps={BasicTourSteps}
                        run={this.state.isTourOpen}
                        callback={this.callback}
                        continuous={true}
                        showProgress={true}
                        showSkipButton={true}
                        floaterProps={{
                            wrapperOptions: {
                                placement: "right",
                                offset: 50,
                                position: true,
                            },
                        }}
                    />
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        widgetstate: state.widgetstate,
        user: state.user,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DealerVPP);
