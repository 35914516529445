import createReducer from '../lib/createReducer'
import * as types from '../actions/types'
import 'array-flat-polyfill';

const DEFAULTS = {
    outlook: "",
    myFarmStats: null,
    myFarmYears: [],
    salesCorn: [],
    salesSoy: [],
    bidsPinned: [],
    zipcode: "",
    miles: "",
    grainBidCommodityFilters: ["corn", "soybean", "wheat", "other"], // WEB version
    grainBidFilterExcludesDeliveryMonths: []
};

export const bennettapp = createReducer(DEFAULTS, {

    [types.RESET_TO_DEFAULTS]() {
        return DEFAULTS;
    },

    [types.SET_OUTLOOK](state, action) {

        // clone the existing state
        let newState = Object.assign({}, state);

        // overwrite existing outlook
        newState.outlook = action.outlook;
        return newState;

    },

    [types.SET_MY_FARM_STATS](state, action) {
        let newState = Object.assign({}, state);
        newState.myFarmStats = action.stats;
        return newState;
    },

    [types.SET_FARM_REPORT_YEARS](state, action) {
        let newState = Object.assign({}, state);
        newState.myFarmYears = action.years;
        return newState;
    },

    [types.SET_SALES_RECORDS](state, action) {
        let newState = Object.assign({}, state);

        // bail out early if we did not get any data.
        if (!action.salesRecords) {
            return newState;
        }

        const soySales = action.salesRecords.soy;
        if (soySales) {
            let aggregateSoySales = Object.entries(soySales).map((year, index) => {
                // we only care about non null records
                if (year.length !== 2) {
                    return null;
                }
                const BASE_TEN = 10;
                let reportYear = parseInt(year[0], BASE_TEN);
                let yearData = year[1];
                let nonNullSales = yearData.filter((sale) => {
                    return sale.date !== null && sale.bu_sold > 0 && sale.net_price > 0;
                });
                nonNullSales = nonNullSales.map(sale => {
                    sale.reportYear = reportYear;
                    return sale;
                });
                return nonNullSales;
            });

            if (aggregateSoySales) {
                aggregateSoySales = aggregateSoySales.flat();
            }

            newState.salesSoy = aggregateSoySales.sort((sale1, sale2) => {
                const d1 = new Date(sale1.date);
                const d2 = new Date(sale2.date);
                if (d1.getTime() < d2.getTime()) {
                    return -1;
                } else if (d1.getTime() > d2.getTime()) {
                    return 1;
                }
                return 0;
            })
        }

        const cornSales = action.salesRecords.corn;
        if (cornSales) {
            let aggregateCornSales = Object.entries(cornSales).map((year, index) => {
                // we only care about non null records
                if (year.length !== 2) {
                    return null;
                }
                const BASE_TEN = 10;
                let reportYear = parseInt(year[0], BASE_TEN);

                let yearData = year[1];
                let nonNullSales = yearData.filter((sale) => {
                    return sale.date !== null && sale.bu_sold > 0 && sale.net_price > 0;
                });
                nonNullSales = nonNullSales.map(sale => {
                    sale.reportYear = reportYear;
                    return sale;
                });
                return nonNullSales;
            });

            if (aggregateCornSales) {
                aggregateCornSales = aggregateCornSales.flat();
            }

            newState.salesCorn = aggregateCornSales.sort((sale1, sale2) => {
                const d1 = new Date(sale1.date);
                const d2 = new Date(sale2.date);
                if (d1.getTime() < d2.getTime()) {
                    return -1;
                } else if (d1.getTime() > d2.getTime()) {
                    return 1;
                }
                return 0;
            })
        }

        return newState;
    },

    [types.SET_PINNED_BIDS](state, action) {
        let newState = Object.assign({}, state);
        if (action.bids !== null) {
            newState.bidsPinned = action.bids;
        } else {
            newState.bidsPinned = [];
        }
        return newState;
    },

    [types.SET_ZIPCODE](state, action) {
        let newState = Object.assign({}, state);
        newState.zipcode = action.zipcode;
        return newState;
    },

    [types.SET_SEARCH_RADIUS_MILES](state, action) {
        let newState = Object.assign({}, state);
        newState.miles = action.miles;
        return newState;
    },

    /**
     Lets users exclude certain delivery months for Cash Bids table
     */
    [types.SET_FILTER_FOR_DELIVERY_MONTH](state, action) {
        let newState = Object.assign({}, state);
        newState.grainBidFilterExcludesDeliveryMonths = action.deliveryMonths;
        return newState;

    },

    /**
     Lets users filter out different commodities from the Cash Bids table
     // WEB API
     */
    [types.SET_FILTERS_FOR_COMMODITIES___WEB](state, action) {
        let newState = Object.assign({}, state);
        newState.grainBidCommodityFilters = action.values;
        return newState;
    },

});
