import React, { Component } from 'react';

import Flexbox from 'flexbox-react';
import ErrorView from '../views/ErrorView';

import logo from '../assets/bennett_logo.png';


class ErrorScreen extends Component {

  render() {
    return (
      <Flexbox flexDirection="column" style={{minHeight: "100vh"}}>
        <Flexbox flexDirection="row" justifyContent="center" alignItems="center" style={{background: "#2F475D", padding: 10}}>
          <a href="/">
            <img src={logo} alt="AgMarket" height={40}/>
          </a>
        </Flexbox>
        <Flexbox flexDirection="column" justifyContent="center" alignItems="center" flexGrow={10} style={{color: "white"}}>
          <ErrorView {...this.props} />
        </Flexbox>
      </Flexbox>
    );
  }
}

export default ErrorScreen;
