import React from "react";

import {connect} from "react-redux";
import {ActionCreators} from "../actions";
import {bindActionCreators} from "redux";

import {withRouter} from "react-router";

import {Icon, Modal} from 'semantic-ui-react';
import Flexbox from 'flexbox-react';

import uploadGraphic from '../assets/upload-modal.svg';
import uploadFailGraphic from '../assets/upload-modal--fail.svg';
import '../styles/UploadModal.scss';

import {
    JOB_STATE_PENDING,
    JOB__PUBLISH,
    JOB__IMAGE_UPLOAD,
    JOB__VIDEO_UPLOAD
} from "Constants";

class JobQueueDirector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeJob: null,
            progress: 0,
            isUploadingArticle: false,
            currentMediaIndex: 0,
            totalMediaToUpload: 0,
            imageLoaded: false,
            failed: false
        };
    }

    componentDidMount() {
        this._processQueue();
    }

    componentDidUpdate(prevProps) {
        if (this.props.uploadQueue !== prevProps.uploadQueue) {
            this._processQueue();
        }
    }

    componentWillUnmount() {
    }

    _clearQueue = event => {

        this.props.clearJobQueue();
        this.setState({activeJob: null, failed: false});
        event.stopPropagation();
    };

    _processQueue = () => {
        if (this.state.activeJob !== undefined && this.state.activeJob !== null) {
            return; // we already have an active job.
        }

        const job = this.props.uploadQueue.find(job => {
            if (job.state === JOB_STATE_PENDING) {
                return job;
            }
            this.setState({progress: 0});
            return null;
        });

        if (job) {
            // try processing the next job on the queue
            this.setState({activeJob: job});

            switch (job.jobType) {
                case JOB__PUBLISH:
                    // let's process this job
                    // this.setState({ isBurst: true })
                    this.startPublishProcessWithJob(job);
                    break;
                case JOB__IMAGE_UPLOAD:
                    // this.startImageUploadWithData(job.data);
                    break;
                case JOB__VIDEO_UPLOAD:
                    // this.startVideoUploadWithData(job.data);
                    break;
                default:
            }
        }
    };

    loadImage = () => {
        this.setState({imageLoaded: true});
    }

    restartQueue = () => {
        this.setState({activeJob: null, failed: false});
        const payload = {...this.props.uploadQueue[0].payload};
        const jobTitle = payload.title;
        this.props.clearJobQueue();
        this.props.enqueueJob(JOB__PUBLISH, payload, jobTitle);
    }

    render() {
        return (
            <Modal open={this.state.activeJob !== null} className="upload-modal">
                <Modal.Content className="upload-modal">
                    { !this.state.failed ?
                        <Flexbox flexDirection="column" justifyContent="center" alignItems="center">
                            <img className="upload-modal__graphic" src={uploadGraphic} onLoad={this.loadImage} alt="uploading graphic" />
                            {this.state.imageLoaded && <>
                                <progress className="upload-modal__progress-bar" value={this.state.progress} max="100" />
                                <label className="upload-modal__text-please-wait">Please wait... ({this.state.progress.toFixed()}%)</label>
                                <span className="upload-modal__text-upload-in-progress">{this.state.progress >= 100 ? "Now Publishing" : "Upload in Progress"}</span>
                            </>}
                        </Flexbox>
                        :
                        <Flexbox flexDirection="column" justifyContent="center" alignItems="center">
                            <button className="upload-modal__button-close" onClick={this._clearQueue}><Icon circular bordered name='close' /></button>
                            <img className="upload-modal__graphic--fail" src={uploadFailGraphic} alt="upload failed" />
                            <span className="upload-modal__text-fail">Upload has failed.</span>
                            <ul className="upload-modal__text-fail-tips">
                                <li>File may be too large. Max upload size is 5 GB.</li>
                                <li>File may be of an unsupported type.</li>
                                <li>Your connection may be poor.</li>
                                <li>There may have been an issue with your browser. If you are using Internet Explorer, consider uploading from Google Chrome.</li>
                            </ul>
                            <Flexbox className="upload-modal__button-row" flexDirection="row" justifyContent="center" alignItems="center">
                                <button className="upload-modal__button-cancel" onClick={this._clearQueue}>Cancel</button>
                                <button className="upload-modal__button-retry" onClick={this.restartQueue}>Retry</button>
                            </Flexbox>
                        </Flexbox>
                    }
                </Modal.Content>
            </Modal>
        );
    }

    /**
     Burst Creation Uploading Job
     */
    startPublishProcessWithJob = job => {
        if (!job || !job.payload) {
            return;
        }

        // do we need to upload any media?
        this.mediaToUpload = [];

        this.articleUploadData = {
            title: job.payload.title,
            abstract: job.payload.abstract,
            release_date: job.payload.release_date,
            is_draft: job.payload.is_draft,
            media: [],
            original_id: job.payload.original_id,
            burst_id: job.payload.burst_id,
            isUpdate: job.payload.isUpdate,
            isDuplicate: job.payload.isDuplicate
        };


        let data = job.payload;

        for (let i = 0; i < data.media.length; i++) {
            const media = data.media[i];

            if (media && media !== null) {
                let mediaObj;

                if (media.video) {
                    mediaObj = media.video;
                    this.articleUploadData.media[i] = {caption: media.file_caption};
                } else if (media.image) {
                    mediaObj = media.image;
                    this.articleUploadData.media[i] = {caption: media.file_caption};
                }

                if (mediaObj && mediaObj.isRemote && mediaObj.id) {
                    // this is a cloud media object that has already been uploaded.
                    this.articleUploadData.media[i].id = mediaObj.id;
                } else if (mediaObj) {
                    // this is a local media object that needs to be uploaded.
                    this.mediaToUpload.push({media, index: i});
                }
            }
        }
        this.setState({totalMediaToUpload: this.mediaToUpload.length});

        this._uploadMedia();
    };

    _uploadMedia = () => {

        if (this.mediaToUpload.length <= 0) {
            // no more left in the job queue
            this.setState({isUploadingArticle: true});

            this.props.createNewVideoArticle(
                this.articleUploadData,
                this._didFinishPublish
            );
            return;
        }

        const media = this.mediaToUpload[0].media;
        const userNote = "";
        const tags = [];
        if (media.video) {
            const video = media.video;

            this.props.uploadVideo(
                video,
                this.props.uid,
                userNote,
                tags,
                this._mediaUploadDidSendMoreData,
                this._didFinishUploadingMedia
            );
        } else {
            const image = media.image;

            this.props.uploadImage(
                image,
                this.props.uid,
                userNote,
                tags,
                this._mediaUploadDidSendMoreData,
                this._didFinishUploadingMedia
            );
        }
    };

    _mediaUploadDidSendMoreData = progress => {
        const curIndex = this.state.totalMediaToUpload - this.mediaToUpload.length;

        // calculate % progress total over all media items
        let jobProgress = ((progress + curIndex) / this.state.totalMediaToUpload ) * 100

        this.setState({
            currentMediaIndex: curIndex + 1,
            progress: jobProgress,
        });
    };

    _didFinishUploadingMedia = (success, message) => {
        if (success) {

            const response = JSON.parse(message);

            const media = this.mediaToUpload[0];
            const index = media.index;

            this.articleUploadData.media[index].id = response.media_id;
            this.setState({failed: false});
            this.mediaToUpload.shift();
            this._uploadMedia();
        } else {
            this.setState({failed: true});
            this.props.setError("Error uploading media: " + message);
        }
    };

    _didFinishPublish = (success, message) => {
        if (success) {
            // remove job from queue
            this.setState({
                isUploadingArticle: false,
                failed: false,
                activeJob: null,
                imageLoaded: false
            });
            this.props.dequeueJob();
            //this.playSound();

            //  this.props.showAlertWithType('success', 'Upload Successful', `${this.getSuccessMessage()} ${this.props.fullName}!`)
            this.props.setSuccess("Great Work! The article is scheduled for publishing.");
            this.props.history.replace("/");

        } else {
            this.setState({failed: true});
            this.props.setError("Error publishing: " + message);
        }
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        uploadQueue: state.queue.jobs,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(JobQueueDirector));
