import createReducer from '../lib/createReducer'
import * as types from '../actions/types'
import { JOB_STATE_PENDING } from 'Constants';

const DEFAULTS = {
  jobs: [],
};

export const queue = createReducer(DEFAULTS, {

  [types.RESET_TO_DEFAULTS]() {
    return DEFAULTS;
  },

  // push a new job onto the queue
  [types.ENQUEUE_JOB](state, action) {
    let newState = Object.assign({}, state);

    const newJob = {
      jobType: action.jobType,
      progress: 0.0,
      state: JOB_STATE_PENDING,
      title: action.title,
      payload: action.payload
    }

    newState.jobs = immutablePush(newState.jobs, newJob);

    return newState;
  },

  // remove the first most job from the queue
  [types.DEQUEUE_JOB](state, action) {
    let newState = Object.assign({}, state);

    newState.jobs = immutableShift(newState.jobs);

    return newState;
  },

  // clear all jobs from queue
  [types.CLEAR_QUEUE](state, action) {
    let newState = Object.assign({}, state);
    newState.jobs = [];
    return newState;
  },

});

/* We have to use immutable methods in Redux */
function immutableShift(arr){
  return arr.slice(1)
}
function immutablePush(arr, newEntry){
  return [ ...arr, newEntry ]
}
