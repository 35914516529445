import {executeBasicNetworkCall} from "../../lib/Utils";

export function createCharge(token, email, success, failure, uid = null, promo_code = null) {
    return (dispatch, getState) => {
        let json;
        if (uid === null) {
            if (promo_code === null) {
                json = {token: token, email: email};
            } else {
                json = {token: token, email: email, promo_code: promo_code};
            }

        } else {
            if (promo_code === null) {
                json = {token, email, uid}
            } else {
                json = {token, email, uid, promo_code}
            }

        }
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "create_payment",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x.error) {
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        success(x.receipt)
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

export function UpdateUserFromCharge(uid, receipt, success, failure) {
    return (dispatch, getState) => {
        let json = {uid: uid, receipt: receipt};
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "create_or_update_user_from_charge",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x.error) {
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        success(x.subscription_expire_date)
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

export function CreateUserFromCharge(first_name, last_name, email, region, password, phone, marketing_type, receipt, success, failure) {
    return (dispatch, getState) => {
        let json = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            region: region,
            password: password,
            phone_number: phone,
            receipt: receipt,
            marketing_type
        };
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "create_or_update_user_from_charge",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x.error) {
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        success(x.subscription_expire_date)
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

export function getAllActiveRegions(callback) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_all_active_regions",
            undefined,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x.error) {
                        //console.log(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        callback(x.data)
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

// This gets the "How did you hear about us list"
export function getAllMarketingTypes(callback = () => {
}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_all_marketing_types",
            undefined,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x.error) {
                        //console.log(x.message)
                        callback(false, x.message)
                    } else {
                        callback(true, x.data)
                        //console.log(x.message)

                    }
                }
            },
            error => {
                console.log(error);
                callback(false, error)
            },
        );
    }
}

export function getSubscriptionCost(is_create, promo_code, callback = () => {
}, failure = () => {
}) {
    return (dispatch, getState) => {

        let json = {is_create, promo_code};
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "get_subscription_cost",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x.error) {
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        callback(x.data)
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            }
        );
    }
}

export function getSubscriptionCostUpdate(promo_code, uid, callback = () => {
}, vppCallback = () => {
}, failure = () => {
}) {
    return (dispatch, getState) => {

        let json = {is_create: false, promo_code, uid};
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "get_subscription_cost",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x.error) {
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        if (x.data === 'vpp') {
                            vppCallback(x.expiration_date)
                        } else {
                            callback(x.data)
                        }
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

export function getSubscriptionCostRegister(promo_code, email, first_name, last_name, belongs_to_region_id, phone_number, password, marketing_type, callback = () => {
}, vppCallback = () => {
}, failure = () => {
}) {
    return (dispatch, getState) => {

        let json = {
            is_create: true,
            promo_code,
            email,
            first_name,
            last_name,
            belongs_to_region_id,
            phone_number,
            password,
            marketing_type
        };
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "get_subscription_cost",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x.error) {
                        console.log(x.message)
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        if (x.data === 'vpp') {
                            vppCallback(x.expiration_date)
                        } else {
                            callback(x.data)
                        }
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

export function validateNewAccountInfo(promoCode, isJoin, firstName, lastName, region, email, password, phone, marketing_type, recaptchaToken, login, goToBilling, failure) {
    return (dispatch, getState) => {
        let json = {
            promo_code: promoCode,
            is_join: isJoin,
            first_name: firstName,
            last_name: lastName,
            region: region,
            email: email,
            phone_number: phone,
            password: password,
            recaptcha_token: recaptchaToken,
            marketing_type
        };
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "validate_new_account_info",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x.error) {
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        if (x.created_user) {
                            goToBilling(x.expiration_date)
                        } else {
                            goToBilling()
                        }
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

export function validateBillingRedirect(uid, firstName, lastName, email, goToBilling, failure) {
    return (dispatch, getState) => {
        let json = {uid: uid, first_name: firstName, last_name: lastName, email: email};
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "validate_billing_redirect",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x.error) {
                        console.log(x.message)
                        failure()
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        goToBilling(uid, firstName, lastName, email)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

