import React, {Component, Suspense} from 'react';
import 'signup/css/App.css';
import {Route, Switch} from 'react-router-dom'
import {StripeProvider, Elements} from 'react-stripe-elements';
import {DEBUG} from '../../Constants'
import LazyLoadingComponentPlaceholder from 'viewerapp/components/LazyLoadingComponentPlaceholder';
import MyErrorBoundary from 'viewerapp/components/MyErrorBoundary'

//Dealer Base
const DealerGeneratePromoCodeScreen = React.lazy(() => import("signup/containers/DealerGeneratePromoCodeScreen"))
const DealerNewUserOrSignInScreen = React.lazy(() => import("signup/containers/DealerNewUserOrSignInScreen"))
const DealerBillingScreen = React.lazy(() => import("signup/containers/DealerBillingScreen"))
const DealerPaymentSuccessScreen = React.lazy(() => import("signup/containers/DealerPaymentSuccessScreen"))


class DealerBase extends Component {


    render() {

        const renderDealerGeneratePromoCodeScreen = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
                        <DealerGeneratePromoCodeScreen {...allProps}/>
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        const renderDealerNewUserOrSignInScreen = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
                        <DealerNewUserOrSignInScreen {...allProps}/>
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        const renderDealerPaymentSuccessScreen = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
                        <DealerPaymentSuccessScreen {...allProps}/>
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        const renderDealerBillingScreen = (props) => {
            let stripeKey = 'pk_test_EGzVQpkrgziSInftllnG5AJw'
            if (!DEBUG()) {
                stripeKey = 'pk_live_b2BGuyBBPul9R9RUDuUGbqLj'
            }

            // console.log(stripeKey)
            const allProps = Object.assign(props, this.props)

            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder/>}>
                        <StripeProvider apiKey={stripeKey}>
                            <Elements>
                                <DealerBillingScreen {...allProps}/>
                            </Elements>
                        </StripeProvider>
                    </Suspense>
                </MyErrorBoundary>
            )
        };


        return (
            <Switch>
                <Route exact path='/' render={renderDealerGeneratePromoCodeScreen}/>
                {/*<Route exact path='/app/dealer/2' component={DealerGeneratePromoCodeScreen2}/>*/}
                <Route exact path='/dealer/sign-in' render={renderDealerNewUserOrSignInScreen}/>

                <Route exact path='/dealer/payment-success' render={renderDealerPaymentSuccessScreen}/>

                <Route exact path='/dealer/billing' render={renderDealerBillingScreen}/>

                <Route exact path='/dealer/:dealername' render={renderDealerGeneratePromoCodeScreen}/>

            </Switch>
        )
    }
}

export default DealerBase
