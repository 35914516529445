import * as types from "./types";
import * as BennettActions from "./bennettapp";
import "../lib/obfuscate";
import MyBugSnag from "../../lib/bugsnag";
import * as Constants from "../../Constants";

import { subYears, format } from "date-fns";
import { executeBasicNetworkCall } from "../../lib/Utils";

//const apiKey = "59c08c7c9e29331a30f82e155b74d6d0";
const obfuscatedAPIKey = "mqhpoqjqkkikh pjimmolnh";

export function fetchCashBids() {
    return (dispatch, getState) => {
        const apiKey = obfuscatedAPIKey.defs(56);
        //  console.log("restoredStr == " + restoredStr);
        const zipcode = getState().bennettapp.zipcode;
        const miles = getState().bennettapp.miles;

        //  console.log("fetching cash bids for zipcode ["+zipcode+"] in mile radius ["+miles+"]...");

        if (!zipcode || zipcode.length < 5) {
            console.log("Zipcode has not been set... cancelling API call to get cash bids...");
            return;
        }

        //  getGrainBids.json?apikey=59c08c7c9e29331a30f82e155b74d6d0&zipCode=67025&maxDistance=50
        const endpoint = "getGrainBids.json?getAllBids=1&apikey=" + apiKey + "&zipCode=" + zipcode + "&maxDistance=" + miles;
        const host = "https://ondemand.websol.barchart.com/";

        executeBasicNetworkCall(
            "",
            undefined,
            "application/json",
            "GET",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);

                    if (x !== null) {
                        dispatch(setCashBids(x.results));
                        dispatch(BennettActions.getPinnedCashBids());
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
            `${host}${endpoint}`,
            false,
        );
    };
}

function setCashBids(data) {
    return {
        type: types.SET_CASH_BIDS,
        data,
    };
}

export function fetchCornAndSoyFutures() {
    return (dispatch, getState) => {
        const apiKey = obfuscatedAPIKey.defs(56);
        //  console.log("restoredStr == " + restoredStr);

        //  getQuote.json?apikey=59c08c7c9e29331a30f82e155b74d6d0&symbols=ZC^F,ZS^F

        const endpoint = "getQuote.json?apikey=" + apiKey + "&symbols=ZC^F,ZS^F,ZW^F,LE^F,GF^F,HE^F";
        const host = "https://ondemand.websol.barchart.com/";

        executeBasicNetworkCall(
            "",
            undefined,
            "application/json",
            "GET",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);

                    if (x !== null) {
                        dispatch(setCornAndSoyFutures(x.results));
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
            `${host}${endpoint}`,
            false,
        );
    };
}

function setCornAndSoyFutures(data) {
    return {
        type: types.SET_CORN_AND_SOY_FUTURES,
        data,
    };
}

export function fetchHistoryForSymbol(symbol) {
    return (dispatch, getState) => {
        // CHECK to see if we already have this information and it is still fresh...
        let shouldRefreshFromBarchartAPI = true;

        const cachedValue = getState().barcharts.history[symbol];
        if (cachedValue != null) {
            // we have hit the barcharts API before for this symbol... have we done it within the past hour?
            const currentTime = new Date().getTime();

            if (cachedValue.lastFetched != null) {
                if (currentTime - cachedValue.lastFetched < 3600 * 1000) {
                    // 1 hour
                    shouldRefreshFromBarchartAPI = false;
                } else {
                    console.log("current time: " + currentTime);
                    console.log("cached time: " + cachedValue.lastFetched);
                    console.log("delta: " + (currentTime - cachedValue.lastFetched));
                }
            } else {
                // this cached value does not have a timestamp associated with it.
            }
        }

        if (shouldRefreshFromBarchartAPI) {
            const apiKey = obfuscatedAPIKey.defs(56);
            //  console.log("restoredStr == " + restoredStr);

            //  getHistory.json?apikey=59c08c7c9e29331a30f82e155b74d6d0&symbol=ZCZ18&type=daily&startDate=20161101000000
            //  getQuote.json?apikey=59c08c7c9e29331a30f82e155b74d6d0&symbols=ZC^F,ZS^F
            let startDate = subYears(new Date(), 1);
            const endpoint =
                "getHistory.json?apikey=" +
                apiKey +
                "&symbol=" +
                symbol +
                "&type=daily&startDate=" +
                format(startDate, "YYYYMMdd");
            const host = "https://ondemand.websol.barchart.com/";

            executeBasicNetworkCall(
                "",
                undefined,
                "application/json",
                "GET",
                getState,
                (reqq) => {
                    if (reqq.responseText != null) {
                        const x = JSON.parse(reqq.responseText);
                        console.log(x);

                        if (x !== null) {
                            const d = new Date();
                            const millisecondsSinceEpoch = d.getTime();
                            dispatch(setHistoryForSymbol(x.results, millisecondsSinceEpoch));
                        }
                    }
                },
                (error) => {
                    MyBugSnag.getInstance().client.notify(error);
                },
                false,
                (status) => {
                    MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
                },
                `${host}${endpoint}`,
                false,
            );
        } else {
            // we should dispatch the cached data
            dispatch(updateLastHistoryLoadTimestamp());
        }
    };
}

function setHistoryForSymbol(data, timestamp) {
    return {
        type: types.SET_HISTORY_FOR_SYMBOL,
        response: { data: data, timestamp: timestamp },
    };
}

function updateLastHistoryLoadTimestamp() {
    return {
        type: types.UPDATE_LAST_HISTORY_LOAD_TIMESTAMP,
    };
}

export function fetchCropHistory() {
    return (dispatch, getState) => {
        // CHECK to see if we already have this information and it is still fresh...
        // let shouldRefreshFromBarchartAPI = true;

        // what crop year are we viewing?
        const selectedCropYear = getState().widgetstate.mySelectedFarmYear;
        if (selectedCropYear === undefined || selectedCropYear === null) {
            return;
        }

        // X = crop year
        // Time Range =  July 1st, X-1 <----> June 30th, X+1
        const startDate = new Date(`July 1, ${selectedCropYear - 1}`);
        const endDate = new Date(`June 30, ${selectedCropYear + 1}`);

        let commoditySymbol;
        if (getState().widgetstate.activeMyFarmCommodity === Constants.CORN_TYPE) {
            commoditySymbol = "ZCZ" + selectedCropYear.toString().slice(-2);
        } else if (getState().widgetstate.activeMyFarmCommodity === Constants.SOYBEAN_TYPE) {
            commoditySymbol = "ZSZ" + selectedCropYear.toString().slice(-2);
        }

        const apiKey = obfuscatedAPIKey.defs(56);

        const endpoint = `getHistory.json?apikey=${apiKey}&symbol=${commoditySymbol}&type=weekly&startDate=${format(
            startDate,
            "YYYYMMdd",
        )}&endDate=${format(endDate, "YYYYMMdd")}`;
        const host = "https://ondemand.websol.barchart.com/";

        executeBasicNetworkCall(
            "",
            undefined,
            "application/json",
            "GET",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);

                    if (x !== undefined && x !== null) {
                        if (!x.error && x.results) {
                            dispatch(setCropYearData(selectedCropYear, getState().widgetstate.activeMyFarmCommodity, x.results));
                        }
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
            `${host}${endpoint}`,
            false,
        );
    };
}

function setCropYearData(year, commodity, data) {
    return {
        type: types.SET_CROP_YEAR_DATA,
        year,
        commodity,
        data,
    };
}

export function resetCashBidSearch() {
    return {
        type: types.RESET_REDUX_CASH_BID_SEARCH,
    };
}
