import createReducer from '../lib/createReducer'
import * as types from '../actions/types'


export const customers = createReducer({list: []}, {

    [types.SET_MY_USERS](state, action) {
        let newState = Object.assign({}, state);
        if (action.customers !== null) {
            newState.list = action.customers;
        } else {
            newState.list = [];
        }
        return newState;
    },

});
