import React, { Component, Suspense } from "react";
import "../../App.css";
import "viewerapp/styles/messages.css";

import Flexbox from "flexbox-react";

import { Route, Switch } from "react-router-dom";
import { isIE } from "react-device-detect";
import { Sidebar } from "semantic-ui-react";

import { connect } from "react-redux";
import { ActionCreators } from "viewerapp/actions";
import { bindActionCreators } from "redux";

import CoBrandedLogoPopup from "viewerapp/components/CoBrandedLogoPopup";
import { AMReturnBar } from "innercast-core-js";

import Joyride from "react-joyride";
import { EVENTS } from "react-joyride/es/constants";

import { BasicTourSteps } from "../../viewerapp/tour";
import * as Constants from "../../Constants";
import { AuthGroup, BackgroundBlue } from "../../Constants";
import SidebarContent from "../../viewerapp/views/SidebarContent";
import TopBar from "../../viewerapp/views/TopBar";

import "react-component-countdown-timer/lib/styles.css";
import { format } from "date-fns";

import ExtendSubscriptionModal from "../../viewerapp/components/CRMExtensions/ExtendSubscriptionModal";
import { Elements, StripeProvider } from "react-stripe-elements";

import "../../viewerapp/styles/error.css";
import "../../styles/LoginView.css";
import "../../viewerapp/styles/MyFarmSummary.css";
import "../../viewerapp/styles/util.css";

import LazyLoadingComponentPlaceholder from "../../viewerapp/components/LazyLoadingComponentPlaceholder";
import MyErrorBoundary from "../../viewerapp/components/MyErrorBoundary";
import JobQueueDirector from "../../viewerapp/containers/JobQueueDirector";
import ChangeLogModal from "../../viewerapp/views/subviews/ChangeLogModal";

const BasicEditorScreen = React.lazy(() =>
    import(/* webpackChunkName: "EditorScreen" */ "../../viewerapp/containers/BasicEditorScreen"),
);
const PromoCodeScreen = React.lazy(() =>
    import(/* webpackChunkName: "EditorScreen" */ "../../viewerapp/containers/PromoCodeScreen"),
);
const HedgeReports = React.lazy(() =>
    import(/* webpackChunkName: "HedgeReports" */ "../../viewerapp/views/reports/HedgeReports"),
);
const Home = React.lazy(() => import(/* webpackChunkName: "DashboardScreen" */ "../../viewerapp/containers/DashboardScreen"));
const CashBids = React.lazy(() => import(/* webpackChunkName: "CashBidsScreen" */ "../../viewerapp/containers/CashBidsScreen"));
const CashBidsDetailScreen = React.lazy(() =>
    import(/* webpackChunkName: "CashBidsDetailsScreen" */ "../../viewerapp/containers/CashBidsDetailScreen"),
);
const MyFarmSummary = React.lazy(() =>
    import(/* webpackChunkName: "MyFarmSummaryScreen" */ "../../viewerapp/containers/MyFarmSummaryScreen"),
);
const MyFarmReportsScreen = React.lazy(() =>
    import(/* webpackChunkName: "MyFarmReportsScreen" */ "../../viewerapp/containers/MyFarmReportsScreen"),
);
const FuturesScreen = React.lazy(() =>
    import(/* webpackChunkName: "FuturesScreen" */ "../../viewerapp/containers/FuturesScreen"),
);
const FuturesDetailScreen = React.lazy(() =>
    import(/* webpackChunkName: "FuturesDetailScreen" */ "../../viewerapp/containers/FuturesDetailScreen"),
);
const NewsScreen = React.lazy(() => import(/* webpackChunkName: "NewsScreen" */ "../../viewerapp/containers/NewsScreen"));
const CRMManageScreen = React.lazy(() =>
    import(/* webpackChunkName: "CRMManageScreen" */ "../../viewerapp/containers/CRMManageScreen"),
);
const NewReportWizardScreen = React.lazy(() =>
    import(/* webpackChunkName: "NewReportWizardScreen" */ "../../viewerapp/containers/reports/NewReportWizardScreen"),
);

const TweeterHomeParent = React.lazy(() =>
    import(/* webpackChunkName: "TweeterHomeScreen" */ "../../viewerapp/containers/TweeterHomeParent"),
);

const mql = window.matchMedia(`(min-width: 800px)`);

class App extends Component {
    constructor(props) {
        super(props);

        this.props.validate();

        const width = window.innerWidth;
        this.state = {
            tourCount: 0,
            isTourOpen: false,
            sidebarDocked: width > 800,
            sidebarOpen: width > 800 && this.props.widgetstate.sidebarVisible,
            sidebarVisibleOverride: this.props.widgetstate.sidebarVisible,
        };
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }

    UNSAFE_componentWillMount() {
        mql.addListener(this.mediaQueryChanged);
    }

    componentWillUnmount() {
        if (this.state.mql) {
            this.state.mql.removeListener(this.mediaQueryChanged);
        }
    }

    componentDidMount() {
        if (!this.props.widgetstate.mainTourHasBeenWatched && this.props.widgetstate.mainTourHasBeenWatched !== null) {
            // main tour has not been viewed... show it.
            this._showMainTour();
        }
    }

    componentDidUpdate() {
        if (!this.props.widgetstate.mainTourHasBeenWatched && this.props.widgetstate.mainTourHasBeenWatched !== null) {
            // main tour has not been viewed... show it.
            this._showMainTour();
        }
    }

    _showMainTour = () => {
        if (!this.state.sidebarOpen || !this.state.sidebarDocked) {
            setTimeout(
                () =>
                    this.setState({
                        tourCount: this.state.tourCount + 1,
                        isTourOpen: true,
                    }),
                250,
            );
        } else {
            this.setState({
                sidebarOpen: true,
                tourCount: this.state.tourCount + 1,
                isTourOpen: true,
            });
        }

        this.props.setMainTourAsWatched();
    };

    callback = (tour) => {
        const { index, type } = tour;

        if (type === EVENTS.STEP_BEFORE && index === 1) {
            // open the sidebar if necessary before continuing
            if (!this.state.sidebarOpen || !this.state.sidebarDocked) {
                this.setState({
                    isTourOpen: false,
                    sidebarOpen: true,
                });
                setTimeout(
                    () =>
                        this.setState({
                            isTourOpen: true,
                        }),
                    250,
                );
            }
        } else if ([EVENTS.TOUR_END, EVENTS.CLOSE, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Sunce this is a controlled tour you'll need to update the state to advance the tour
            this.setState({ isTourOpen: false });
        }
    };

    onSetSidebarOpen(open) {
        if (!this.state.isTourOpen) {
            this.setState({ sidebarOpen: open });
        }
    }

    mediaQueryChanged() {
        if (this.props.widgetstate.sidebarVisible) {
            this.setState({ sidebarDocked: mql.matches, sidebarOpen: mql.matches });
        }
    }

    _handleNavClick = () => {
        if (!this.state.isTourOpen) {
            this.setState({ sidebarOpen: this.state.sidebarDocked });
        }
    };

    _toggleSidebarDocked = () => {
        const newVal = !this.props.widgetstate.sidebarVisible;

        this.props.toggleSidebarVisible(newVal);
        if (newVal) {
            this.setState({ sidebarOpen: true, sidebarDocked: true });
        } else {
            this.setState({ sidebarOpen: false, sidebarDocked: false });
        }
    };

    renderViewerapp = () => {
        const isAM = this.props.user.groupID === AuthGroup.AccountManager;
        const isPM = this.props.user.groupID === AuthGroup.ProjectManager;
        const isAuthor = this.props.user.groupID === AuthGroup.Author;
        
        //  const isViewer = this.props.user.groupID === AuthGroup.Viewer;

        const renderDashboard = (props) => {
            // this is the catch-all route, so if the user does something stupid like put in /asdglfkajsdglkj
            // then the app will redirect them to something more sensible like /app
            if (props.location.pathname !== "/app") {
                props.history.replace("/app");
            }

            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <Home {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };
        const renderMyFarm = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <MyFarmSummary {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        const renderMyFarmProfitOptimizer = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <HedgeReports {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        const renderMyFarmReports = (props) => {
            const allProps = Object.assign(props, this.props, { sidebarVisible: this.state.sidebarOpen });
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <MyFarmReportsScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };
        const renderNews = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <NewsScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        const renderCompose = (props) => {
            const allProps = Object.assign(props, this.props);

            return isAM || isPM || isAuthor ? (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <BasicEditorScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            ) : null;
        };

        const renderPromoCode = (props) => {
            const allProps = Object.assign(props, this.props);

            return isAM || isPM ? (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <PromoCodeScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            ) : null;
        };

        const renderCashBids = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <CashBids {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };
        const renderCashBidsDetails = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <CashBidsDetailScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };
        const renderFutures = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <FuturesScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };
        const renderFutureDetails = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <FuturesDetailScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };
        const renderCRM = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <CRMManageScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        const renderNewReportWizard = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <NewReportWizardScreen {...allProps} />
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        const renderMobileCRM = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <div style={{ backgroundColor: "white" }}>
                            <CRMManageScreen {...allProps} />
                        </div>
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        const renderTweeterHome = (props) => {
            const allProps = Object.assign(props, this.props);
            return (
                <MyErrorBoundary>
                    <Suspense fallback={<LazyLoadingComponentPlaceholder />}>
                        <div style={{ backgroundColor: "white", width: "100%", height: "100%" }}>
                            <TweeterHomeParent {...allProps} />
                        </div>
                    </Suspense>
                </MyErrorBoundary>
            );
        };

        let sidebarVisible = this.state.sidebarOpen;
        let animation = this.state.sidebarDocked ? "slide along" : "overlay";
        let contentLeftMargin = sidebarVisible && this.state.sidebarDocked ? 170 : 0;
        //<Route exact path="/crm/mobile-user-details/:asker/:target/:token" render={renderMobileCRM}/>

        let sub_expire_date =
            this.props.user.subscription_expire_date !== null ? new Date(this.props.user.subscription_expire_date) : null;
        let two_weeks = new Date();
        two_weeks.setDate(two_weeks.getDate() + 14);

        let stripeKey = "pk_test_EGzVQpkrgziSInftllnG5AJw";
        if (!Constants.DEBUG()) {
            stripeKey = "pk_live_b2BGuyBBPul9R9RUDuUGbqLj";
        }

        return (
            <div style={{ flexDirection: "column" }}>
                <div>
                    <Sidebar
                        data-tut="reactour__sidebar"
                        visible={sidebarVisible}
                        animation={animation}
                        className="sidebar"
                        onHide={() => {
                            if (!this.state.sidebarDocked) {
                                this.onSetSidebarOpen(false);
                            }
                        }}
                        width="thin">
                        <SidebarContent
                            onNavClick={this._handleNavClick}
                            toggleSidebarDocked={this._toggleSidebarDocked}
                            {...this.props}
                        />
                    </Sidebar>

                    <div
                        style={{
                            background: BackgroundBlue,
                            color: "white",
                            marginLeft: contentLeftMargin,
                            height: "100vh",
                        }}>
                        <TopBar
                            menuIsVisible={this.state.sidebarDocked && this.props.widgetstate.sidebarVisible}
                            didAskForMenu={this.onSetSidebarOpen}
                            showMainTour={this._showMainTour}
                            user={this.props.user}
                        />
                        <div
                            style={{
                                position: "relative",
                                top: Constants.TOP_BAR_HEIGHT,
                                right: 0,
                                bottom: 0,
                                height: `calc(100% - ${Constants.TOP_BAR_HEIGHT})`,
                            }}>
                            <AMReturnBar user={this.props.user} />
                            {this.props.user.groupID === Constants.AuthGroup.Viewer &&
                                sub_expire_date !== null &&
                                sub_expire_date < two_weeks && (
                                    <Flexbox
                                        style={{ width: "100%", minHeight: "50px", backgroundColor: "#ff6700", padding: "5px" }}
                                        justifyContent="center"
                                        alignItems="center"
                                        flexWrap="wrap">
                                        <b style={{ padding: 10, marginRight: "5px", fontSize: "20px" }}>
                                            Your subscription expires on {format(sub_expire_date, "MMM d, ") + sub_expire_date.getFullYear() + format(sub_expire_date, " p")}.
                                        </b>
                                        <StripeProvider apiKey={stripeKey}>
                                            <Elements>
                                                <ExtendSubscriptionModal
                                                    user={this.props.user}
                                                    selectedUID={this.props.user.uid}
                                                    isForCRM={false}
                                                    {...this.props}
                                                />
                                            </Elements>
                                        </StripeProvider>
                                        {/*<CountdownTimer {...settings}/>*/}
                                    </Flexbox>
                                )}
                            <Switch>
                                <Route exact path="/app" render={renderDashboard} {...this.props} />
                                <Route exact path="/app/cashbids" render={renderCashBids} {...this.props} />
                                <Route path="/app/cashbids/:locationID/:bidID" render={renderCashBidsDetails} {...this.props} />
                                <Route exact path="/app/myfarm" render={renderMyFarm} {...this.props} />
                                <Route exact path="/app/myfarm/reports" render={renderMyFarmReports} {...this.props} />
                                <Route
                                    exact
                                    path="/app/myfarm/ProfitOptimizer"
                                    render={renderMyFarmProfitOptimizer}
                                    {...this.props}
                                />
                                <Route exact path="/app/myfarm/reports/new" render={renderNewReportWizard} {...this.props} />
                                <Route exact path="/app/futures" render={renderFutures} {...this.props} />
                                <Route path="/app/futures/:symbol" render={renderFutureDetails} {...this.props} />
                                <Route exact path="/app/news" render={renderNews} {...this.props} />
                                {(isAM || isPM || isAuthor) && <Route exact path="/app/compose" render={renderCompose} {...this.props} />}
                                <Route exact path="/app/crm" render={renderCRM} {...this.props} />
                                <Route exact path="/app/crm/myaccount/:asker/:target/:token" render={renderMobileCRM} />
                                <Route exact path="/app/chirper" render={renderTweeterHome} {...this.props} />
                                {(isAM || isPM) && (
                                    <Route exact path="/app/promo_code" render={renderPromoCode} {...this.props} />
                                )}
                                <Route render={renderDashboard} {...this.props} />
                            </Switch>
                        </div>
                    </div>
                    <JobQueueDirector
                        uid={this.props.user.uid}
                        setSuccess={this.props.setSuccess}
                        setError={this.props.setError}
                    />
                    <ChangeLogModal />
                </div>
            </div>
        );
    };

    render() {
        //  const authenticated = true;//this.props.user.isAuthenticated;

        return (
            <div key={this.state.tourCount}>
                {this.renderViewerapp()}
                {isIE ? null : (
                    <Joyride
                        steps={BasicTourSteps}
                        run={this.state.isTourOpen}
                        callback={this.callback}
                        continuous={true}
                        showProgress={true}
                        showSkipButton={true}
                    />
                )}
                <CoBrandedLogoPopup />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        barcharts: state.barcharts,
        cashbids: state.barcharts.cashbids,
        outlook: state.bennettapp.outlook,
        myFarmStats: state.bennettapp.myFarmStats,
        widgetstate: state.widgetstate,
        articles: state.storydynamics.articles,
        user: state.user,
        messages: state.messages,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
