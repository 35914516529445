import React, {Component} from 'react';
import '../../../App.css';
import {NavLink} from 'react-router-dom'
import Flexbox from 'flexbox-react';
import {BackgroundBlue, MenuBlue} from '../../styles';

class SidebarItem extends Component {

    renderIcon = () => {
        if (this.props.iconName) {
            const icon2x = require('../../assets/' + this.props.iconName + '@2x.png');
            const icon3x = require('../../assets/' + this.props.iconName + '@3x.png');
            return (
                <img
                    alt=''
                    src={require("../../assets/" + this.props.iconName + ".png")}
                    srcSet={`
                        ${icon2x} 2x,
                        ${icon3x} 3x
                    `}
                />
            );
        }
        if (this.props.svgIcon) {
            return (
                <img
                    style={{height: 26, width: 26}}
                    alt=''
                    src={this.props.svgIcon}
                />
            )
        }
        return <div style={{width: 26}}/>;
    };

    render() {

        let leftPadding = 28;
        if (this.props.submenuLevel) {
            leftPadding += 28 * (this.props.submenuLevel);
        }
        if (this.props.link === "/app") {
            return (
                <NavLink
                    exact
                    onClick={this.props.onClick}
                    to={this.props.link}
                    style={{textDecoration: 'none', backgroundColor: MenuBlue, width: "100%"}}
                    activeStyle={{backgroundColor: BackgroundBlue}}>
                    <Flexbox data-tut={this.props.dataTut} flexDirection="row" alignItems="center" style={{
                        color: 'white',
                        textDecoration: 'none',
                        paddingLeft: leftPadding,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingRight: 20
                    }}>
                        {this.renderIcon()}
                        <div style={{marginLeft: 10}}>
                            {this.props.title}
                        </div>
                    </Flexbox>
                </NavLink>
            );
        }

        return (
            <NavLink
                onClick={this.props.onClick}
                to={this.props.link}
                style={{textDecoration: 'none', backgroundColor: MenuBlue, width: "100%"}}
                activeStyle={{backgroundColor: BackgroundBlue}}>
                <Flexbox data-tut={this.props.dataTut} flexDirection="row" alignItems="center" style={{
                    color: 'white',
                    textDecoration: 'none',
                    paddingLeft: leftPadding,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingRight: 20
                }}>
                    {this.renderIcon()}
                    <div style={{marginLeft: 10}}>
                        {this.props.title}
                    </div>
                </Flexbox>
            </NavLink>
        );
    }
}

export default SidebarItem;
