import createReducer from '../lib/createReducer'
import * as types from '../actions/types'

const DEFAULTS = {
  zipcode: "",
  miles: "",
  cornAndSoyFutures: [],
  history: {},
  lastHistoryLoad: 0,
  cropYear: {},
};

export const barcharts = createReducer(DEFAULTS, {

  [types.RESET_TO_DEFAULTS]() {
    return DEFAULTS;
  },

  [types.SET_CORN_AND_SOY_FUTURES](state, action) {

    // clone the existing state
    let newState = Object.assign({}, state);

    // overwrite existing card width
    newState.cornAndSoyFutures = action.data;
    return newState;

  },

  [types.SET_CASH_BIDS](state, action) {

    // clone the existing state
    let newState = Object.assign({}, state);

    newState.cashbids = action.data;

    return newState;

  },

  [types.SET_HISTORY_FOR_SYMBOL](state, action) {

    if(action.response.data.length <= 0) {
      return state;
    }

    // clone the existing state
    let newState = Object.assign({}, state);
  //  newState.history = {}; // this line for testing purposes
    // find the symbol to use as a key
    const firstDataPoint = action.response.data[0];
    const symbol = firstDataPoint.symbol;

    if (symbol) {

      newState.history[symbol] = {data:action.response.data,
                           lastFetched: action.response.timestamp};

      newState.lastHistoryLoad = action.response.timestamp;
    }
    return newState;
  },

  [types.UPDATE_LAST_HISTORY_LOAD_TIMESTAMP](state, action) {
    // clone the existing state
    let newState = Object.assign({}, state);
    newState.lastHistoryLoad = new Date().getTime();
    return newState;
  },

  [types.SET_CROP_YEAR_DATA](state, action) {
    let newState = Object.assign({}, state);

    newState.cropYear[action.year] = Object.assign({}, state.cropYear[action.year]);

    newState.cropYear[action.year][action.commodity] = action.data;

    return newState;
  },

  [types.RESET_REDUX_CASH_BID_SEARCH](state) {
    let newState = Object.assign({}, state);
    newState.zipcode = "";
    newState.miles = "";
    return newState;
  }

});
