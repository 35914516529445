import React, { Component } from "react";

import "../../App.css";

import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { bindActionCreators } from "redux";

import { Icon, Image, Transition } from "semantic-ui-react";
import Flexbox from "flexbox-react";
import AgMarketLogo from "viewerapp/assets/bennett_logo.png";
import { BACKGROUND_BLUE } from "../../Constants";

const POPUP_VISIBLE_TIME_SECONDS = 10;

class CoBrandedLogoPopup extends Component {
    constructor(props) {
        super(props);
        this.state = { visible: false };
    }

    componentDidMount() {
        if (!this.props.widgetstate.shown_co_brand && this.props.widgetstate.co_branded_image !== null) {
            this.setState({ visible: true });
            this.clearPopup = setTimeout(() => {
                this.hideCoBrand();
            }, POPUP_VISIBLE_TIME_SECONDS * 1000);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.widgetstate.co_branded_image === null &&
            this.props.widgetstate.co_branded_image !== null &&
            !this.props.widgetstate.shown_co_brand
        ) {
            this.setState({ visible: true });
            this.clearPopup = setTimeout(() => {
                this.hideCoBrand();
            }, POPUP_VISIBLE_TIME_SECONDS * 1000);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.clearPopup);
        this.hideCoBrand();
    }

    hideCoBrand = () => {
        this.setState({ visible: false }, () => this.props.shownCoBrand());
    };

    render() {
        return (
            <Transition visible={this.state.visible} animation="fly up" duration={1100}>
                <Flexbox
                    style={{
                        position: "fixed",
                        bottom: 20,
                        right: 20,
                        borderRadius: "5px",
                        marginLeft: 20,
                        backgroundColor: "white",
                        border: "3px solid grey",
                        maxWidth: "400px",
                    }}>
                    <Icon
                        style={{
                            position: "absolute",
                            top: -15,
                            right: -15,
                            color: "black",
                            zIndex: 5,
                            backgroundColor: "#b3b3b3",
                            cursor: "pointer",
                        }}
                        onClick={this.hideCoBrand}
                        circular
                        name="delete"
                    />
                    <Flexbox style={{ backgroundColor: BACKGROUND_BLUE, padding: "20px" }}>
                        <Image src={AgMarketLogo} style={{ width: "100%" }} />
                    </Flexbox>
                    <Flexbox flexDirection="column" justifyContent="center" alignItems="center" style={{ padding: "20px" }}>
                        <h2 style={{ color: "black" }}>brought to you by</h2>
                        <a href={"https://" + this.props.widgetstate.co_branded_website_url} align="center">
                            <Image src={this.props.widgetstate.co_branded_image} style={{ width: "100%" }} />
                        </a>
                    </Flexbox>
                </Flexbox>
            </Transition>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        widgetstate: state.widgetstate,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoBrandedLogoPopup);
