import * as types from "./types";
import * as BarchartActions from "./barchart";
import MyBugSnag from "../../lib/bugsnag";
import { HOST } from "../../Constants";
import { executeBasicNetworkCall } from "../../lib/Utils";

export function fetchOutlook() {
    return (dispatch, getState) => {
        const endpoint = "/API/v1/outlook";

        executeBasicNetworkCall(
            "am_outlook",
            undefined,
            "application/json",
            "GET",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);

                    dispatch(setOutlook(x.msg));
                }
            },
            (error) => {
                dispatch(setOutlook("this is a sample outlook"));
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
            `${HOST()}${endpoint}`,
        );
    };
}

function setOutlook(outlook) {
    return {
        type: types.SET_OUTLOOK,
        outlook,
    };
}

export function fetchMyFarmStats() {
    return (dispatch, getState) => {
        const username = getState().user.username;

        const params = JSON.stringify({
            username,
        });

        executeBasicNetworkCall(
            "my_farm_stats",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    dispatch(setMyFarmStats(x));

                    if (x && x.sales_records) {
                        dispatch(setSalesRecords(x.sales_records));
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
        );
    };
}

function setMyFarmStats(stats) {
    return {
        type: types.SET_MY_FARM_STATS,
        stats,
    };
}

export function fetchMyFarmReportYears(usernameProp, userIDProp) {
    return (dispatch, getState) => {
        let username = usernameProp;
        let userID = userIDProp;

        if (username === undefined || username === null || userID === undefined || userID === null) {
            const user = getState().user;
            if (!user) {
                console.error("Could not get user from Redux store.  Are they logged in?");
                return;
            }

            username = user.username;
            userID = user.uid;
        }

        const params = JSON.stringify({ username, userID });

        executeBasicNetworkCall(
            "",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);

                    if (x && !x.error) {
                        dispatch(setMyFarmReportYears(x.data));
                    } else if (x && x.error) {
                        MyBugSnag.getInstance().client.notify(x.error);
                    } else {
                        MyBugSnag.getInstance().client.notify("My Farm Report Years API call failed unexpectantly.");
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
            `${HOST()}/features/device/yearsList`,
        );
    };
}

function setMyFarmReportYears(years) {
    return {
        type: types.SET_FARM_REPORT_YEARS,
        years,
    };
}

export function addReportYear(year) {
    return (dispatch, getState) => {
        const user = getState().user;
        if (!user) {
            console.error("Could not get user from Redux store.  Are they logged in?");
            return;
        }
        const username = user.username;
        const userID = user.uid;

        const params = JSON.stringify({ username, userID, value: year });

        executeBasicNetworkCall(
            "",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);

                    if (x && !x.error) {
                        dispatch(fetchMyFarmReportYears());
                    } else if (x && x.error) {
                        MyBugSnag.getInstance().client.notify(x.error);
                    } else {
                        MyBugSnag.getInstance().client.notify("Add Report Year API failed unexpectantly.");
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
            `${HOST()}/features/device/addReports`,
        );
    };
}

export function fetchSaleRecords(request = { year: null, commodity: null }) {
    return (dispatch, getState) => {
        if (request.year === null || request.commodity === null) {
            return;
        }

        const user = getState().user;
        if (!user) {
            console.error("Could not get user from Redux store.  Are they logged in?");
            return;
        }
        const username = user.username;
        const userID = user.uid;

        let endpoint = "/features/device/reports/v2/CSR";
        if (request.commodity.toLowerCase() === "soy") {
            endpoint = "/features/device/reports/v2/SSR";
        } else {
            //console.log(request.commodity.toLowerCase());
        }

        let paramsObj = {
            year: request.year,
            username,
            userID,
        };

        const params = JSON.stringify(paramsObj);

        executeBasicNetworkCall(
            "",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);

                    if (x && !x.error) {
                        dispatch(setSalesRecords(request.commodity, request.year, x.data));
                    } else if (x && x.error) {
                        MyBugSnag.getInstance().client.notify(x.error);
                    } else {
                        //MyBugSnag.getInstance().client.notify("Add Report Year API failed unexpectantly.");
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
            `${HOST()}${endpoint}`,
        );
    };
}

function setSalesRecords(salesRecords) {
    return {
        type: types.SET_SALES_RECORDS,
        salesRecords,
    };
}

export function toggleCashBidPinned(bid_id) {
    return (dispatch, getState) => {
        const user = getState().user;
        if (!user) {
            console.error("Could not get user from Redux store.  Are they logged in?");
            return;
        }

        let paramsObj = { bid_id };

        const params = JSON.stringify(paramsObj);

        executeBasicNetworkCall(
            "toggle_cash_bid_pin",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);

                    if (x && !x.error) {
                        dispatch(getPinnedCashBids());
                    } else if (x && x.error) {
                        MyBugSnag.getInstance().client.notify(x.error);
                    } else {
                        //MyBugSnag.getInstance().client.notify("Add Report Year API failed unexpectantly.");
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
        );
    };
}

export function getPinnedCashBids() {
    return (dispatch, getState) => {
        const user = getState().user;
        if (!user) {
            console.error("Could not get user from Redux store.  Are they logged in?");
            return;
        }

        executeBasicNetworkCall(
            "get_pinned_bids",
            undefined,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);

                    if (x && !x.error) {
                        dispatch(setPinnedBids(x.bids));
                    } else if (x && x.error) {
                        // console.log(x.message)
                    } else {
                        //MyBugSnag.getInstance().client.notify("API GetPinnedBids failed.");
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
        );
    };
}

function setPinnedBids(bids) {
    return {
        type: types.SET_PINNED_BIDS,
        bids,
    };
}

export function updateCashBidsSearch(zip_code, radius, excluded_months, commodities, callback = () => {}) {
    return (dispatch, getState) => {
        const user = getState().user;
        if (!user) {
            console.error("Could not get user from Redux store.  Are they logged in?");
            return;
        }

        if (zip_code === null) {
            zip_code = getState().bennettapp.zipcode;
        }
        if (radius === null) {
            radius = getState().bennettapp.miles;
        }
        if (excluded_months === null) {
            excluded_months = getState().bennettapp.grainBidFilterExcludesDeliveryMonths;
        }
        if (commodities === null) {
            commodities = getState().bennettapp.grainBidCommodityFilters;
        }

        excluded_months = excluded_months.join(",");
        commodities = commodities.join(",");

        const params = JSON.stringify({ zip_code, radius, excluded_months, commodities });
        // console.log(params);

        executeBasicNetworkCall(
            "save_cash_bids_search",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);

                    if (x && !x.error) {
                        dispatch(fetchCashBidsSearch(callback));
                    } else if (x && x.error) {
                        console.log(x.message);
                    } else {
                        //MyBugSnag.getInstance().client.notify("API GetPinnedBids failed.");
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
        );
    };
}

export function fetchCashBidsSearch(callback = () => {}) {
    return (dispatch, getState) => {
        const user = getState().user;
        if (!user) {
            console.error("Could not get user from Redux store.  Are they logged in?");
            return;
        }

        executeBasicNetworkCall(
            "fetch_cash_bids_search",
            undefined,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);

                    if (x && !x.error) {
                        const search_settings = x.search_settings;
                        // console.log("Settings:", search_settings);
                        dispatch(setZipcode(search_settings.zip_code));
                        dispatch(setSearchRadiusMiles("" + search_settings.radius));
                        dispatch(setCommodityFilterValues(search_settings.commodities));
                        dispatch(setFilterForDeliveryMonthExclusion(search_settings.excluded_months));
                        dispatch(BarchartActions.fetchCashBids());
                        callback();
                    } else if (x && x.error) {
                        console.log(x.message);
                    } else {
                        //MyBugSnag.getInstance().client.notify("API GetPinnedBids failed.");
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
        );
    };
}

export function setZipcode(zipcode = null) {
    return {
        type: types.SET_ZIPCODE,
        zipcode,
    };
}

export function setSearchRadiusMiles(miles = "50") {
    return {
        type: types.SET_SEARCH_RADIUS_MILES,
        miles,
    };
}

// web api
export function setCommodityFilterValues(values) {
    return {
        type: types.SET_FILTERS_FOR_COMMODITIES___WEB,
        values,
    };
}

export function setFilterForDeliveryMonthExclusion(deliveryMonths) {
    return {
        type: types.SET_FILTER_FOR_DELIVERY_MONTH,
        deliveryMonths,
    };
}

export function resetCashBidFilters() {
    return (dispatch, getState) => {
        const user = getState().user;
        if (!user) {
            console.error("Could not get user from Redux store.  Are they logged in?");
            return;
        }

        dispatch(updateCashBidsSearch(null, null, [], ["corn", "soybean", "wheat", "other"]));
    };
}

export function archiveReportYear(year) {
    return (dispatch, getState) => {
        // can also take token for mobile usage
        const params = JSON.stringify({ year });

        const endpoint = "/features/device/deleteReportYear";

        executeBasicNetworkCall(
            "",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);

                    if (x && !x.error) {
                        dispatch(fetchMyFarmReportYears());
                    } else if (x && x.error) {
                        MyBugSnag.getInstance().client.notify(x.error);
                    } else {
                        //MyBugSnag.getInstance().client.notify("Add Report Year API failed unexpectantly.");
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
            `${HOST()}${endpoint}`,
        );
    };
}

export function createNewReportYear(formData, callback = () => {}) {
    return (dispatch, getState) => {
        ///features/device/addReportsV2_1

        const params = JSON.stringify(formData);
        console.log(params);

        executeBasicNetworkCall(
            "device_report_add_new_v2_1",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(reqq.responseText);
                    if (x && !x.error) {
                        callback(true);
                    } else if (x && x.error) {
                        MyBugSnag.getInstance().client.notify(x.error);
                        callback(false);
                    } else {
                        //MyBugSnag.getInstance().client.notify("Add Report Year API failed unexpectantly.");
                        callback(false);
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
            false,
            (status) => {
                callback(false);
                MyBugSnag.getInstance().client.notify(`Server ${status} Error`);
            },
        );
    };
}

export function setShouldSendToHubspot(forUID, isExempt, callback = () => {}) {
    return (dispatch, getState) => {
        const params = JSON.stringify({
            uid: forUID,
            isExempt,
        });

        executeBasicNetworkCall(
            "set_hubspot_exempt",
            params,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    callback();
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
        );
    };
}

export function getBranchLocations(callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_branch_locations",
            undefined,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true, x.branch_list);
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
        );
    };
}

export function getUserBranchLocation(uid, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_user_branch_location",
            JSON.stringify({ uid }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        callback(true, x.user_branch);
                    }
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
        );
    };
}

export function setUserBranchLocation(uid, branch_id, callback = () => {}) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "set_user_branch_location",
            JSON.stringify({ uid, branch_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);
                }
            },
            (error) => {
                MyBugSnag.getInstance().client.notify(error);
            },
        );
    };
}

export function fetchUserDetailsInfo(target_uid, token = null, completionCallback = () => {}) {
    return (dispatch, getState) => {
        let params = {
            requested: target_uid,
        };
        if (token !== null) {
            params.auth_token = token;
        }
        params = JSON.stringify(params);

        executeBasicNetworkCall("crm_user_details_info", params, "application/json", "POST", getState, (reqq) => {
            if (reqq.responseText != null) {
                const x = JSON.parse(reqq.responseText);
                // console.log("did fetch user details info", x);
                if (x.error) {
                    //dispatch(MessageActions.setError(x.msg));
                } else {
                    //dispatch(setUserDetailsInfo(x.data));
                    completionCallback(x.data);
                }
            }
        });
    };
}
