import createReducer from '../lib/createReducer'
import * as types from '../actions/types'


const DEFAULTS = {
    error_message: '',
    success_message: ''
};

export const messages = createReducer(DEFAULTS, {

    [types.RESET_TO_DEFAULTS]() {
        return DEFAULTS;
    },

    [types.SET_ERROR](state, action) {
        let newState = Object.assign({}, state);
        if (action.message !== null) {
            newState.error_message = action.message;
        } else {
            newState.error_message = '';
        }
        return newState;
    },
    [types.SET_SUCCESS](state, action) {
        let newState = Object.assign({}, state);
        if (action.message !== null) {
            newState.success_message = action.message;
        } else {
            newState.success_message = '';
        }
        return newState;
    }

});
