import * as types from './types';
import {executeBasicNetworkCall} from "../../lib/Utils";

export function createPromoCode(code, quota, percent_off, code_expiration_date, callback = () => {}, failure = () => {}) {
    return (dispatch, getState) => {

        let params = {code, quota, percent_off, code_expiration_date}
        params = JSON.stringify(params)

        executeBasicNetworkCall(
            "create_promo_code",
            params,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);

                    if (x.error) {
                        console.log(x.message)
                        failure(x.message)
                    } else {
                        console.log(x.message)
                        callback()
                    }
                }
            },
            error => {
                console.log(error);
            }
        );
        //TODO clear all redux states here!!!

    }
}

export function updatePromoCode(promo_code_id, new_code, new_quota, new_percent_off, new_code_expiration_date, is_archived, callback = () => {}, failure = () => {}) {
    return (dispatch, getState) => {

        let params = {promo_code_id, new_code, new_quota, new_percent_off, new_code_expiration_date, is_archived}
        params = JSON.stringify(params)


        executeBasicNetworkCall(
            "update_promo_code",
            params,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);

                    if (x.error) {
                        console.log(x.message)
                        failure(x.message)
                    } else {
                        console.log(x.message)
                        callback()
                    }
                }
            },
            error => {
                console.log(error);
            }
        );


    }
}

export function getPromoCodes() {
    return (dispatch, getState) => {


        executeBasicNetworkCall(
            "get_promo_codes",
            undefined,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    console.log(x);

                    if (x.error) {
                        console.log(x.message)
                    } else {
                        console.log(x.message)
                        console.log(x.data)
                        dispatch(setPromoCodes(x.data))
                    }
                }
            },
            error => {
                console.log(error);
            }
        );

    }
}

function setPromoCodes(promo_codes){
    return {
        type: types.SET_PROMO_CODES,
        promo_codes
    }
}