import * as types from './types';
import {HOST} from 'Constants'
import {executeBasicNetworkCall} from "../../lib/Utils";

// Get all API URL endpoints
export function validate() {
    return (dispatch, getState) => {
        const url = HOST() + "/API/v2/validate";

        const reqq = new XMLHttpRequest();
        reqq.onreadystatechange = e => {
            try {
                if (reqq.readyState !== 4) {
                    return;
                }
                if (reqq.status === 200) {
                    if (reqq.responseText != null) {
                        const x = JSON.parse(reqq.responseText);
                        if (x.error) {
                            dispatch(setEndpointURLS(null));
                        } else {
                            if (x.data) {
                                // console.log(x.data)
                                const info = x.data;
                                dispatch(setEndpointURLS(info));
                            }
                        }
                    }
                } else {
                    dispatch(setEndpointURLS(null));
                }
            } catch (error) {
                //
                //  MyBugSnag.getInstance().client.notify(error);
                dispatch(setEndpointURLS(null));
            }
        };
        reqq.open("POST", url);
        reqq.send();
    };
}

function setEndpointURLS(info) {
    return {
        type: types.SET_ENDPOINT_URLS,
        info
    };
}


export function fetchArticles(requestOld = false) {
    return (dispatch, getState) => {


        const user = getState().user;
        if (!user) {
            console.error("Could not get user from Redux store.  Are they logged in?");
            return;
        }
        const userID = user.uid;

        let params = "uid=" + userID;
        if (requestOld === true) {

            const articles = getState().storydynamics.articles;

            if (articles !== undefined && articles !== null && articles.length > 0) {

                const lastID = articles[articles.length-1].id;

                if (lastID) {
                    params += `&request_old=true&last_id=${lastID}`;
                }
            }
        }

       // console.log(params);

        executeBasicNetworkCall(
            // "bb_info",
            undefined,
            params,
            "application/x-www-form-urlencoded",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    //console.log("bb_info", x);

                    if (x.error) {
                      //  console.log(x);
                        dispatch(setArticles(null));
                    } else {
                        if (x.data) {
                            const articles = x.data;
                            dispatch(setArticles(articles, requestOld));
                        }
                    }
                }
            },
            error => {
                console.log(error);
                //  MyBugSnag.getInstance().client.notify(error);
                dispatch(setArticles(null));
            },
            false,
            status => {
                console.log(`Server ${status} Error`);
            },
            `${HOST()}/API/v1/bbInfo`
        );
    }
}

function setArticles(articles, isAppending = false) {
    return {
        type: types.SET_ARTICLES,
        articles,
        isAppending
    }
}
