import {executeBasicNetworkCall} from "../../lib/Utils";

export function CreateVendorAccount(company_name, email, password, success, failure) {
    return (dispatch, getState) => {
        let json = {company_name: company_name, email: email, password: password}
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "create_vendor_account",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);

                    if (x.error) {
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        success()
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

export function LoginAsDealer(email, password, success, failure) {
    return (dispatch, getState) => {

        let json = {email: email, password: password};
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "login_as_dealer",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);

                    if (x.error) {
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        success()
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            }
        );
    }
}

export function GetDealerBillingInfo(quota, success, failure) {
    return (dispatch, getState) => {
        let json = {quota: quota};
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "get_dealer_billing_info",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);

                    if (x.error) {
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        success(x.data.cost, x.data.name, x.data.email, x.data.normal_price_per_account)
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

export function createDealerPayment(quota, token, email, success, failure) {
    return (dispatch, getState) => {
        let json = {quota: quota, token: token, email: email};
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "create_dealer_payment",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);

                    if (x.error) {
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        success(x.data.charge_id, x.data.quota)
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

export function BankAccountProcessing(PLAID_LINK_PUBLIC_TOKEN, ACCOUNT_ID, quota, email, company_name, success, failure) {
    return (dispatch, getState) => {
        let json = {PLAID_LINK_PUBLIC_TOKEN: PLAID_LINK_PUBLIC_TOKEN, ACCOUNT_ID: ACCOUNT_ID, quota: quota, email: email, company_name: company_name};
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "bank_account_processing",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);

                    if (x.error) {
                        // console.log(x.message)
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        success(x.data.charge_id, x.data.quota)
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

export function createVPPLicense(charge_id, quota, success, failure) {
    return (dispatch, getState) => {
        let json = {quota: quota, charge_id: charge_id};
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "create_vpp_license",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);

                    if (x.error) {
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        success(x.license_expire_date)
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

export function getDealerDetails(CoBrandedURL, success, failure){
    return (dispatch, getState) => {
        let json = {CoBrandedURL: CoBrandedURL};
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "get_dealer_details",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);

                    if (x.error) {
                        failure()
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        success(x.data)
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}

export function validatePromoCode(promo_code, user_id, success, failure){
    return (dispatch, getState) => {
        let json = {promo_code: promo_code, user_id: user_id};
        json = JSON.stringify(json);

        executeBasicNetworkCall(
            "validate_promo_code",
            json,
            "application/json",
            "POST",
            getState,
            reqq => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    // console.log(x);
                    if (x.error) {
                        failure(x.message)
                        // dispatch(ErrorActions.setError(x.message));
                        // dispatch(setInviteCodeHistory(null));
                    } else {
                        success()
                        //console.log(x.message)
                        //updateMessage(x.message)
                        // dispatch(ErrorActions.clearError());
                        // dispatch(setInviteCodeHistory(x.data));
                    }
                }
            },
            error => {
                console.log(error);
                // dispatch(ErrorActions.setError(error));
                // dispatch(setInviteCodeHistory(null));
                //  MyBugSnag.getInstance().client.notify(error);
            },
        );
    }
}