import { HOST } from "../Constants";

export function getURLForEndpointName(name, urls) {
    for (let i = 0; i < urls.length; i++) {
        const endpoint = urls[i];
        if (endpoint.name === name) {
            return endpoint.url;
        }
    }
}

export function executeBasicNetworkCall(
    endpoint,
    params,
    contentType,
    httpMethod = "POST",
    getState,
    onReady = () => {},
    onError = () => {},
    debug = false,
    onBadStatus = () => {},
    overrideURL,
    withCredentials = true,
) {
    if (debug) {
        // eslint-disable-next-line no-console
        console.log(endpoint);
    }

    const endpoints = getState().storydynamics.endpoints;
    if (endpoints === null && overrideURL === undefined) {
        return;
    }
    if ((endpoint === undefined || endpoint === null) && overrideURL === undefined) {
        return;
    }

    let URL = overrideURL;

    if (URL === undefined || URL === null) {
        // BUILD THE API URL
        const API_VERSION = endpoints.current_api_version;
        const ENDPOINT = getURLForEndpointName(endpoint, endpoints.urls);
        URL = `${HOST()}/API/${API_VERSION}${ENDPOINT}`;
    }

    if (debug) {
        // eslint-disable-next-line no-console
        console.log(URL);
    }

    const reqq = new XMLHttpRequest();
    reqq.onreadystatechange = e => {
        try {
            if (reqq.readyState !== 4) {
                return;
            }
            if (debug) {
                // eslint-disable-next-line no-console
                console.log(reqq);
            }
            if (reqq.status !== 200) {
                onBadStatus(reqq.status);
                //bugsnagClient.notify(new Error(`endpoint returned status ${reqq.status}`));
            } else {
                onReady(reqq);
            }
        } catch (error) {
            onError(error);
            // bugsnagClient.notify(error);
        }
    };
    reqq.open(httpMethod, URL);
    reqq.setRequestHeader("Content-Type", contentType);
    reqq.withCredentials = withCredentials;
    reqq.send(params);
}

export function formatMoney(n, c, d, t) {
    c = isNaN((c = Math.abs(c))) ? 2 : c;
    d = d === undefined ? "." : d;
    t = t === undefined ? "," : t;
    let s = n < 0 ? "-" : "";
    let i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)), 10));
    let j = undefined;
    j = (j = i.length) > 3 ? j % 3 : 0;

    return (
        s +
        (j ? i.substr(0, j) + t : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
        (c
            ? d +
              Math.abs(n - i)
                  .toFixed(c)
                  .slice(2)
            : "")
    );
}

// immutable array splice function
export function splice(input, start, deleteCount) {
    deleteCount = deleteCount === null ? input.length - start : deleteCount;
    const items = [].slice.call(arguments, 3);
    let output;
    return (output = input.slice(0, start)).concat.apply(output, items).concat(input.slice(start + deleteCount));
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
